import React, { Fragment } from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Row,
    Col,
    Container,
    CardBody,
    Alert,
    Button,
    ButtonGroup,
    Input,
    Label
} from 'reactstrap';
import classnames from 'classnames';
import {
    api,
    helpers,
    date_helpers,
    constants
} from '../utils';
import {
    DropzoneUploader
} from '../components';
import EditProductColorsWidths from './EditProductColorsWidths';
import EditProductMemoInventory from './EditProductMemoInventory';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import moment from 'moment';
import ToggleButton from 'react-toggle-button';

let emptyProduct = {
    id: null,
    dcName: '',
    dcNumber: '',
    supplierName: '',
    supplierNumber: '',
    webCategories: [],
    webProductDescription: '',
    standardCustomRugDsc: '',
    supplier: null,
    yarnBrand: null,
    productType: {
        value: "1",
        label: "CARPET"
    },
    pileType: null,
    // rugIntlCollection: null,
    // rugShape: null,
    // rugComplexity: null,
    memoSize: null,
    unitCost: "0",
    rawCost: "0",
    unitPrice: "0",
    volumeCost: "0",
    volumePrice: "0",
    netVolumeCost: "0",
    useCost: {
        value: "1",
        label: "CUT"
    },
    specPrice: "",
    margin: "",
    priceUnitType: {
      value: constants.PRICE_UNIT_TYPE.SQ_YARDS.value,
      label: constants.PRICE_UNIT_TYPE.SQ_YARDS.label
    },
    productStatus: null,
    constructionType: null,
    carpetProductType: null,
    colorAndGrainType: null,
    installGrade: null,
    installMethod: [],
    patternMatch: '',
    faceWeight: '',
    rollSize: "0",
    run: '',
    repeat: '',
    warranties: '',
    repeatLength: "0",
    repeatWidth: "0",
    thickness: '',
    ply: 0,
    itemWidth: '',
    itemLength: '',
    edge: '',
    perContainer: '',
    squareFeetPerCarton: "0.00",
    memoShelfNumber: '',
    collectionSeq: null,
    basicSpecs: '',
    pileHeight: '',
    orderByDC: true,
    showOnWeb: false,
    salableAsStandardCustomRug: false,
    showSampleInventory: false,
    isCustomRugProduct: false,
    hasRemoteInventory: false,
    comment: '',
    allowCommission: false,
    cleaningComment: '',
    backingType: null,
    isRugQuotable: true
};

const emptyMiscProduct = {
    id: null,
    dcName: '',
    dcNumber: '',
    supplierName: '',
    supplierNumber: '',
    webCategories: [],
    webProductDescription: '',
    standardCustomRugDsc: '',
    supplier: null,
    yarnBrand: null,
    pileType: {
        value: "5",
        label: "UNKNOWN"
    },
    // rugIntlCollection: null,
    // rugShape: null,
    // rugComplexity: null,
    memoSize: null,
    unitCost: "0",
    rawCost: "0",
    unitPrice: "0",
    volumeCost: "0",
    volumePrice: "0",
    netVolumeCost: "0",
    priceUnitType: null,
    productStatus: null,
    constructionType: null,
    carpetProductType: null,
    colorAndGrainType: null,
    installGrade: null,
    installMethod: [],
    patternMatch: '',
    faceWeight: '',
    rollSize: "0",
    run: '',
    repeat: '',
    warranties: '',
    repeatLength: "0",
    repeatWidth: "0",
    thickness: '',
    ply: 0,
    itemWidth: '',
    itemLength: '',
    edge: '',
    perContainer: '',
    squareFeetPerCarton: "0.00",
    memoShelfNumber: '',
    collectionSeq: null,
    basicSpecs: '',
    pileHeight: '',
    orderByDC: true,
    showOnWeb: false,
    salableAsStandardCustomRug: false,
    showSampleInventory: false,
    isCustomRugProduct: false,
    hasRemoteInventory: false,
    comment: '',
    cleaningComment: ''
};

const emptyIntlRugProduct = {
    id: null,
    dcName: '',
    dcNumber: '',
    supplierName: '',
    supplierNumber: '',
    webCategories: [],
    webProductDescription: '',
    standardCustomRugDsc: '',
    supplier: null,
    yarnBrand: null,
    pileType: null,
    // rugIntlCollection: null,
    // rugShape: null,
    // rugComplexity: null,
    memoSize: null,
    unitCost: "0",
    rawCost: "0",
    unitPrice: "0",
    volumeCost: "0",
    volumePrice: "0",
    netVolumeCost: "0",
    priceUnitType: null,
    productStatus: null,
    constructionType: null,
    carpetProductType: null,
    colorAndGrainType: null,
    installGrade: null,
    installMethod: [],
    patternMatch: '',
    faceWeight: '',
    rollSize: "0",
    run: '',
    repeat: '',
    warranties: '',
    repeatLength: "0",
    repeatWidth: "0",
    thickness: '',
    ply: 0,
    itemWidth: '',
    itemLength: '',
    edge: '',
    perContainer: '',
    squareFeetPerCarton: "0.00",
    memoShelfNumber: '',
    collectionSeq: null,
    basicSpecs: '',
    pileHeight: '',
    orderByDC: true, 
    showOnWeb: false,
    salableAsStandardCustomRug: false,
    showSampleInventory: false,
    isCustomRugProduct: false,
    hasRemoteInventory: false,
    comment: '',
    cleaningComment: ''
};

const emptyContact = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
};

export default class ProductDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          routeProductId: props.location.state && props.location.state.productId
            ? props.location.state.productId
            : null,
          activeTab: '1',
          unbrandedYarnBrand: null,
          priceUnitEach: null,
          productTypeList: [],
          priceUnitTypeList: [],
          // rugComplexityList: [],
          // rugShapeList: [],
          sampleBookList: [],
          constructionTypeList: [],
          yarnBrandList: [],
          backingTypeList: [],
          // rugIntlCollectionList: [],
          memoSizeList: [],
          webCategoryList: [],
          pileTypeList: [],
          carpetProductTypeList: [],
          productStatusList: [],
          colorAndGrainTypeList: [],
          colorFamilyList: [],
          widthsList: [],
          // tradeSizesList: [],
          suppliersList: [],
          productAttachmentTypeList: [],
          product: emptyProduct,
          supplier: {
              accountNumber: "",
              contact: emptyContact
          },
          sampleBookProducts: [],
          selectedSampleBook: {
              value: "",
              label: ""
          },
          addingImage: false,
          files: [],
          // this approach was flawed and would not ever work correctly
          isClone: false, // props.location.state ? props.location.state.clone : false,
          productColors: [],
          productWidths: [],
          rugIntlSizes: [],
          productMemoSizes: [],
          // productImages: [],
          productAttachments: [],
          installGradeList: [],
          isMessageVisible: false,
          message: null,
          messageFlavor: null
        };
        this.toggle = this.toggle.bind(this);
        this.getProductToEdit = this.getProductToEdit.bind(this);
        this.getProductSelectsData = this.getProductSelectsData.bind(this);
        this.updateProductState = this.updateProductState.bind(this);
        this.updateCurrencyField = this.updateCurrencyField.bind(this);
        this.handleFormSelectionChange = this.handleFormSelectionChange.bind(this);
        this.handleMultiFormSelectionChange = this.handleMultiFormSelectionChange.bind(this);
        // this.handleFiberSelectionChange = this.handleFiberSelectionChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.calcSpecialPriceMargin = this.calcSpecialPriceMargin.bind(this);
        this.onSupplierChange = this.onSupplierChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.toggleImages = this.toggleImages.bind(this);
        this.onBookSelect = this.onBookSelect.bind(this);
        this.saveNewProduct = this.saveNewProduct.bind(this);
        this.removeBook = this.removeBook.bind(this);
        this.onClone = this.onClone.bind(this);
        this.getProductAttachments = this.getProductAttachments.bind(this);
    }

    componentDidMount() {
      if (this.state.routeProductId) 
      {
        this.getProductToEdit(this.state.routeProductId, false); // this.state.isClone);
      }
      this.getProductSelectsData();
    }

    getProductToEdit(productId, isClone) {
      api.fetch(`Product/GetProduct/${productId}`).then(response => {
          const product = {
            id: isClone ? null : response.data.id,
            createdByUserName: isClone
              ? `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`
              : response.data.createdByUserName,
            createdByUserId: isClone
              ? this.props.currentUser.id
              : response.data.createdByUserId,
            createdAt: isClone
              ? moment().format('MM/DD/YY')
              : response.data.createdAt,
            dcName: response.data.dcName,
            dcNumber: isClone ? '' : response.data.dcNumber,
            supplierName: response.data.supplierName,
            supplierNumber: isClone ? '' : response.data.supplierNumber,
            webProductDescription: isClone ? '' : response.data.webProductDescription,
            standardCustomRugDsc: response.data.standardCustomRugDsc,
            unitCost: _.round(response.data.unitCost, 2).toFixed(2),
            rawCost: _.round(response.data.rawCost, 2).toFixed(2),
            unitPrice: _.round(response.data.unitPrice, 2).toFixed(2),   
            netVolumeCost: _.round(response.data.netVolumeCost, 2).toFixed(2),  
            volumeCost: _.round(response.data.volumeCost, 2).toFixed(2),
            volumePrice: _.round(response.data.volumePrice, 2).toFixed(2),  
            patternMatch: response.data.patternMatch,
            faceWeight: response.data.faceWeight,
            rollSize: response.data.rollSize,
            run: response.data.run,
            repeat: response.data.repeat,
            warranties: response.data.warranties,
            repeatLength:  _.round(response.data.repeatLength, 3).toFixed(3),   
            repeatWidth:  _.round(response.data.repeatWidth, 3).toFixed(3),  
            thickness: response.data.thickness,
            ply: response.data.ply,
            itemWidth: response.data.itemWidth,
            itemLength: response.data.itemLength,
            edge: response.data.edge,
            perContainer: response.data.perContainer,
            squareFeetPerCarton: response.data.squareFeetPerCarton,
            memoShelfNumber: response.data.memoShelfNumber,
            collectionSeq: isClone
              ? null
              : response.data.collectionSeq
                ? response.data.collectionSeq
                : null,
            basicSpecs: response.data.basicSpecs,
            cleaningComment: response.data.cleaningComment,
            isRugQuotable: response.data.isRugQuotable,
            pileHeight: response.data.pileHeight,
            orderByDC: response.data.orderByDC,
            showOnWeb: isClone ? false : response.data.showOnWeb,
            salableAsStandardCustomRug: response.data.salableAsStandardCustomRug,
            showSampleInventory: response.data.showSampleInventory,
            allowCommission: response.data.allowCommission,
            isCustomRugProduct: response.data.isCustomRugProduct,
            hasRemoteInventory: response.data.hasRemoteInventory,
            comment: isClone ? '' : response.data.comment,
            webCategories: response.data.webCategories &&
                response.data.webCategories.length > 0 
                ? _.map(response.data.webCategories, cat => {
                    return helpers.resolveValue(
                        null,
                        cat.id,
                cat.name
                )}) : [],
            supplier: helpers.resolveValue(
                null,
                response.data.supplierId,
                response.data.supplierLabel),
            yarnBrand: helpers.resolveValue(
                null,
                response.data.yarnBrandId,
                response.data.yarnBrandName),
            productType: helpers.resolveValue(
                null,
                response.data.productTypeId,
                response.data.productTypeName),
            pileType: helpers.resolveValue(
                null,
                response.data.pileTypeId,
                response.data.pileTypeName),
            rugIntlCollection: helpers.resolveValue(    // no longer used, keep old value if it exists for now
                null,
                response.data.rugIntlCollectionId,
                response.data.rugIntlCollectionName),
            memoSize: helpers.resolveValue(
                null,
                response.data.defaultMemoSizeId,
                response.data.defaultMemoSizeName),
            rugShape: helpers.resolveValue(             // no longer used, keep old value if it exists for now
                null,
                response.data.rugShapeId,
                response.data.rugShapeName),
            rugComplexity: helpers.resolveValue(        // no longer used, keep old value if it exists for now
                null,
                response.data.rugComplexityId,
                response.data.rugComplexityName),
            priceUnitType: helpers.resolveValue(
                null,
                response.data.priceUnitTypeId,
                response.data.priceUnitTypeName),
            productStatus: helpers.resolveValue(
                null,
                response.data.productStatusId,
                response.data.productStatusName),
            constructionType: helpers.resolveValue(
                null,
                response.data.constructionTypeId,
                response.data.constructionTypeName),
            carpetProductType: helpers.resolveValue(
                null,
                response.data.carpetProductTypeId,
                response.data.carpetProductTypeName),
            installGrade: helpers.resolveValue(
                null,
                response.data.installGradeId,
                response.data.installGradeName),
            colorAndGrainType: helpers.resolveValue(
                null,
                response.data.colorAndGrainTypeId,
                response.data.colorAndGrainTypeName),
            installMethod: response.data.installMethod,
            useCost: {
                value: "1",
                label: "CUT"
            },
            specPrice: "",
            margin: "",
            backingType: helpers.resolveValue(
                null,
                response.data.backingTypeId,
                response.data.backingTypeName)
        };
        const rugIntlSizes = _.map(response.data.rugIntlSizes, item => {
            return {
                ...item,
                cost: _.round(item.cost, 2).toFixed(2),
                price: _.round(item.price, 2).toFixed(2),
                seq: item.seq.toString()
            };
        });
        this.setState({
            product: product,
            productAttachments: response.data.productAttachments,
            sampleBookProducts: response.data.sampleBooks,
            productColors: response.data.productColors,
            productWidths: response.data.productWidths,
            rugIntlSizes: rugIntlSizes,
            productMemoSizes: response.data.productMemoSizes
        }, () => {
            this.onSupplierChange(product.supplier);
        });
    })
    .catch(helpers.catchHandler);
  }

    getProductSelectsData() {
        api.fetch("Product/GetProductSelectsData")
          .then(response => {
            if (response && response.data) {
              const ybList = response.data.yarnBrandList;
              let newState = {
                productTypeList: _.reject(response.data.productTypeList, t => t.value == constants.PRODUCT_TYPE.RUG.value),
                priceUnitTypeList: response.data.priceUnitTypeList,
                // rugComplexityList: response.data.rugComplexityList,
                // rugShapeList: response.data.rugShapeList,
                sampleBookList: response.data.sampleBookList,
                constructionTypeList: response.data.constructionTypeList,
                yarnBrandList: ybList,
                // rugIntlCollectionList: response.data.rugIntlCollectionList,
                memoSizeList: response.data.memoSizeList,
                webCategoryList: response.data.webCategoryList,
                pileTypeList: response.data.pileTypeList,
                carpetProductTypeList: response.data.carpetProductTypeList,
                productStatusList: response.data.productStatusList,
                colorAndGrainTypeList: response.data.colorAndGrainTypeList,
                colorFamilyList: response.data.colorFamilyList,
                widthsList: response.data.widthsList,
                // tradeSizesList: response.data.tradeSizesList,
                suppliersList: response.data.suppliersList,
                productAttachmentTypeList: response.data.productAttachmentTypeList,
                installGradeList: response.data.installGradeList,
                backingTypeList: response.data.backingTypeList
              };
              if (!this.state.routeProductId) {
                newState.unbrandedYarnBrand = _.find(ybList, yb => yb.label === 'Unbranded');
                newState.priceUnitEach = _.find(newState.priceUnitTypeList, x => x.label.toUpperCase() === 'EACH');
                newState.product = {
                  ...this.state.product,
                  yarnBrand: newState.unbrandedYarnBrand
                };
              }
              this.setState(newState);
            }
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    updateProductState(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                [fieldName]: value
            }
        }));
    }

    updateCurrencyField(fieldName, value) {
        if (value) {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    [fieldName]: _.round(value, 2)  // parseFloat(value).toFixed(2)
                }
            }));
        }
    }

    handleFormSelectionChange(elementName, selection) {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                [elementName]: selection
            }
        }));
    }


    handleMultiFormSelectionChange(elementName, selection) {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                [elementName]: selection
            }
        }));
    }
    // handleFiberSelectionChange(elementName, selection) {
    //     api.fetch(`Pile/GetOne/${selection.value}`)
    //     .then((response) => {
    //         this.setState(prevState => ({
    //             ...prevState,
    //             product: {
    //                 ...prevState.product,
    //                 [elementName]: {
    //                     value: selection.value,
    //                     label: selection.label
    //                 },
    //                 yarnBrand: response.data.yarnBrandId 
    //                     ? helpers.resolveValue(
    //                         null,
    //                         response.data.yarnBrandId,
    //                         response.data.yarnBrandName)
    //                     : null,
    //             }
    //         }));
    //     })
    //     .catch(helpers.catchHandler);        
    // }

    handleCheckboxChange(installMethodId, checked) {
        let installMethodList = this.state.product.installMethod.slice();
        if (_.includes(this.state.product.installMethod, installMethodId)) {
            if (!checked) {
                _.remove(installMethodList, x => x === installMethodId)
            }
        } else {
            if (checked) {
                installMethodList.push(installMethodId)
            }
        }
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                installMethod: installMethodList
            }
        }));
    }

    calcSpecialPriceMargin() {
        let cost = 0.00;
        if (this.state.product.useCost.label === 'ROLL') {
            cost = this.state.product.netVolumeCost;
        }
        else {
            cost = this.state.product.unitCost;
        }
        let specialPrice = parseFloat(this.state.product.specPrice);
        let margin = Math.round(100.0*((specialPrice - cost) / specialPrice));
        this.setState({
            product: {
                ...this.state.product,
                margin: `${margin}%`
            }
        });
    }

    onSupplierChange(selection) {
        // this.setState(prevState => ({
        //     ...prevState,
        //     product: {
        //         ...prevState.product,
        //         supplier: {
        //             value: selection.value,
        //             label: selection.label
        //         }
        //     }
        // }));

        api.fetch(`Supplier/GetSupplier/${selection.value}`)
        .then(response => {
            // let contact = _.find(response.data.contacts, x => x.typeOfContactName === "Rep");
            // if (contact) {
            //     const phoneNumber = _.find(contact.phoneNumbers, x => x.isPrimary === true);
            //     contact.phoneNumber = phoneNumber ? phoneNumber : "";
            // } else {
            //     contact = emptyContact;
            // }
            this.setState({
                // supplier: {
                //     ...response.data.supplier,
                //     // contact: contact
                // },
                supplier: response.data.supplier,
                product: {
                    ...this.state.product,
                    supplier: {
                        value: selection.value,
                        label: selection.label
                    }
                }
            });
        })
        .catch(helpers.catchHandler);
    }

    onBookSelect(option) {
        const selectedSampleBookProducts = this.state.sampleBookProducts.slice();
        const alreadyExists = _.find(selectedSampleBookProducts, item => item.sampleBookId === option.value);
        if (alreadyExists) {
            return;
        } else {
            this.setState(prevState => ({
                ...prevState,
                selectedSampleBook: {
                    value: option.value,
                    label: option.label
                },
                sampleBookProducts: _.concat(this.state.sampleBookProducts, option)
            }));
        }
    }

    removeBook(book) {
        if (!window.confirm('Are you sure?')) return;
        if (book.id) {
            const books = this.state.sampleBookProducts.slice();
            const filtered = _.filter(books, x => x.id !== book.id);

            api.post(`Product/DeleteSampleBookProduct/${book.id}`)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        sampleBookProducts: filtered
                    });
                } else {
                  this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
                }
            })
            .catch(helpers.catchHandler);
            return null;
        } else {
            const books = this.state.sampleBookProducts.slice();
            const filtered = _.filter(books, x => x.value !== book.value);
            this.setState({
                sampleBookProducts: filtered
            });
        }
    }

    removeProductAttachment(attachment) {
        if (!window.confirm('This is a permanent delete.  Are you sure?')) return;
        const attachments = this.state.productAttachments.slice();
        const filtered = _.filter(attachments, x => x.id !== attachment.id);

        api
            .post(`Product/DeleteProductAttachment/${attachment.id}`)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        productAttachments: filtered
                    });
                } else {
                  this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
                }
            })
            .catch(helpers.catchHandler);
        return null;
    }


    onCancel() {
        this.setState({ redirect: true });
    }

    toggleImages() {
        this.setState({
            addingImage: !this.state.addingImage
        });
    }

    isFormValid() {
        let warnings = [];
        if (!this.state.product.supplier) {
            warnings.push("Supplier required.");
        }
        if (!this.state.product.productType) {
            warnings.push("Product Type required.");
        }
        if (!this.state.product.carpetProductType 
            && (this.state.product.productType.value !== constants.PRODUCT_TYPE.MISCELLANEOUS.value
                && this.state.product.productType.value !== constants.PRODUCT_TYPE.RUG_COMPONENT.value)) {
            warnings.push("Product Category required.");
        }
        if (!this.state.product.productStatus) {
            warnings.push("Product Status required.");
        }
        if (!this.state.product.supplierName || this.state.product.supplierName.length < 2) {
            warnings.push("Supplier Product Name required.");
        }
        if (!this.state.product.supplierNumber) {
            warnings.push("Supplier Style # required.");
        }
        if (!this.state.product.dcName || this.state.product.dcName.length < 2) {
            warnings.push("Valid DC Name required.");
        }
        if (!this.state.product.dcNumber) {
            warnings.push("DC Style # required.");
        }
        if (!this.state.product.unitCost && this.state.product.unitCost != "0") {
            warnings.push("Net Cut Cost required.");
        }
        if (this.state.product.unitCost < 0) {
            warnings.push("Net Cut Cost must be 0 or greater.");
        }
        if (!this.state.product.rawCost && this.state.product.rawCost != "0") {
            warnings.push("Raw Cut Cost required.");
        }
        if (this.state.product.rawCost < 0) {
            warnings.push("Raw Cut Cost must be 0 or greater.");
        }
        if (!this.state.product.unitPrice && this.state.product.unitPrice != "0") {
            warnings.push("Sale Cut Price required.");
        }
        if (this.state.product.unitPrice < 0) {
            warnings.push("Sale Cut Price must be 0 or greater.");
        }
        if (!this.state.product.netVolumeCost && this.state.product.netVolumeCost != "0") {
            warnings.push("Net Roll Cost required.");
        }
        if (this.state.product.netVolumeCost < 0) {
            warnings.push("Net Roll Cost must be a positive number.");
        }
        if (!this.state.product.volumeCost && this.state.product.volumeCost != "0") {
            warnings.push("Raw Roll Cost required.");
        }
        if (this.state.product.volumeCost < 0) {
            warnings.push("Raw Roll Cost must be a positive number.");
        }
        if (!this.state.product.volumePrice && this.state.product.volumePrice != "0") {
            warnings.push("Sale Roll Price required.");
        }
        if (this.state.product.volumePrice < 0) {
            warnings.push("Sale Roll Price must be a positive number.");
        }
        if (this.state.product.productType.value !== constants.PRODUCT_TYPE.MISCELLANEOUS.value
            && this.state.product.productType.value !== constants.PRODUCT_TYPE.INTL_RUG.value
            && this.state.product.productType.value !== constants.PRODUCT_TYPE.RUG_COMPONENT.value
            && this.state.product.productType.value !== constants.PRODUCT_TYPE.RUG_PAD.value
            && this.state.product.productType.value !== constants.PRODUCT_TYPE.HARD_SURFACE.value
            && this.state.product.productType.value !== constants.PRODUCT_TYPE.BORDER_OR_BINDING.value) {
            // if (!this.state.product.rugIntlCollection) {
            //     warnings.push("Collection required.");
            // }
            if (!this.state.product.run && this.state.product.productType.value == constants.PRODUCT_TYPE.CARPET.value ) {
                warnings.push("Run required.");
            }
            if (!this.state.product.rollSize && this.state.product.rollSize != "0") {
                warnings.push("Roll Size required.");
            }
            if (this.state.product.rollSize < 0) {
                warnings.push("Roll Size must be a positive number.");
            }
            if (!this.state.product.pileType) {
                warnings.push("Fiber required.");
            }
            if (!this.state.product.yarnBrand) {
                warnings.push("Fiber Brand required.");
            }
            if (!this.state.product.repeatWidth && this.state.product.repeatWidth != "0") {
                warnings.push("Repeat Width required.");
            }
            if (this.state.product.repeatWidth < 0) {
                warnings.push("Repeat Width must be a positive number.");
            }
            if (!this.state.product.repeatLength && this.state.product.repeatLength != "0") {
                warnings.push("Repeat Length is required.");
            }
            if (this.state.product.repeatLength < 0) {
                warnings.push("Repeat Length must be a positive number.");
            }
            // if (_.some(this.state.files, file => !file.imageType.value)) {
            //     warnings.push("Image Type required on all images to be uploaded.")
            // }
        }
        if (this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
            || this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
            || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value) {
                if (!this.state.product.pileType) {
                    warnings.push("Fiber required.");
                }
        }

        if (this.state.product.productType.value === constants.PRODUCT_TYPE.INTL_RUG.value) {
            // if (!this.state.product.rugIntlCollection.value) {
            //     warnings.push("Collection required.");
            // }
            if (!this.state.product.pileType) {
                warnings.push("Fiber required.");
            }
            // if (!this.state.product.rugShape.value) {
            //     warnings.push("Rug Shape required.");
            // }
            // if (_.some(this.state.files, file => !file.imageType.value)) {
            //     warnings.push("Image Type required on all images to be uploaded.")
            // }
        }
        if (_.some(this.state.files, file => !file.imageType.value)) {
            warnings.push("Image Type required on all images to be uploaded.")
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger",
                isMessageVisible: true
            });
        } else {
          this.setState({ message: null, isMessageVisible: false });
        }
        return warnings.length === 0;
    }

    formatProductPayload(product) {
        const payload = {
            Id: product.id ? product.id : 0,
            DCName: product.dcName,
            DCNumber: product.dcNumber,
            SupplierName: product.supplierName,
            SupplierNumber: product.supplierNumber,
            WebProductDescription: product.webProductDescription,
            StandardCustomRugDsc: product.standardCustomRugDsc,
            SupplierId: Number(product.supplier.value), 
            YarnBrandId: Number(product.yarnBrand.value),
            ProductTypeId: Number(product.productType.value),
            PileTypeId: Number(product.pileType.value),
            RugIntlCollectionId: product.rugIntlCollection ? Number(product.rugIntlCollection.value) : null,    // no longer used, keep old value if it exists for now
            DefaultMemoSizeId: product.memoSize ? Number(product.memoSize.value) : null,
            RugShapeId: product.rugShape ? Number(product.rugShape.value) : null,                               // no longer used, keep old value if it exists for now
            RugComplexityId: product.rugComplexity ? Number(product.rugComplexity.value) : null,                // no longer used, keep old value if it exists for now
            UnitCost: Number(product.unitCost),
            RawCost: Number(product.rawCost),
            UnitPrice: Number(product.unitPrice),
            NetVolumeCost: Number(product.netVolumeCost),
            VolumeCost: Number(product.volumeCost),
            VolumePrice:Number(product.volumePrice),
            PriceUnitTypeId: product.priceUnitType ? Number(product.priceUnitType.value) : Number(product.priceUnitTypeId),
            ProductStatusId: Number(product.productStatus.value),
            ConstructionTypeId: product.constructionType ? Number(product.constructionType.value) : null,
            CarpetProductTypeId: product.carpetProductType ? Number(product.carpetProductType.value) : null,
            ColorAndGrainTypeId: product.colorAndGrainType ? Number(product.colorAndGrainType.value) : null,
            PatternMatch: product.patternMatch,
            FaceWeight: product.faceWeight,
            RollSize: Number(product.rollSize),
            Run: product.run,
            Repeat: product.repeat,
            Warranties: product.warranties,
            RepeatLength: Number(product.repeatLength),
            RepeatWidth:Number(product.repeatWidth),
            Thickness: product.thickness,
            Ply: Number(product.ply),
            ItemWidth: product.itemWidth,
            ItemLength: product.itemLength,
            Edge: product.edge,
            PerContainer: product.perContainer,
            SquareFeetPerCarton: Number(product.squareFeetPerCarton),//
            MemoShelfNumber: product.memoShelfNumber,
            InstallGradeId: product.installGrade ? Number(product.installGrade.value) : null,
            CollectionSeq: Number(product.collectionSeq),
            BasicSpecs: product.basicSpecs,
            CleaningComment: product.cleaningComment,
            PileHeight: product.pileHeight,
            // OrderByDC: product.orderByDC,
            ShowOnWeb: product.showOnWeb,
            // SalableAsStandardCustomRug: product.salableAsStandardCustomRug,
            ShowSampleInventory: product.showSampleInventory,
            IsCustomRugProduct: product.isCustomRugProduct,
            HasRemoteInventory: product.hasRemoteInventory,
            Comment: product.comment,
            CreatedAt: date_helpers.formatDateForServer(moment()),
            CreatedByUserId: product.createdByUserId ? product.createdByUserId : this.props.currentUser.id,
            InstallMethod: product.installMethod,
            AllowCommission: product.allowCommission,
            IsRugQuotable: product.isRugQuotable,
            BackingTypeId: product.backingType ? Number(product.backingType.value) : null
        };
        return payload;
    }

    formatSampleBookProductsPayload(sampleBookProducts) {
        const formatted = [];
        _.map(sampleBookProducts,
            x => {
                const sampleBookProduct = {
                    Id: x.id ? x.id : 0,
                    SampleBookId: x.value ? x.value : x.sampleBookId,
                    ProductId: this.state.routeProductId ? this.state.routeProductId : 0,
                    CreatedAt: date_helpers.formatDateForServer(moment()),
                    CreatedByUserId: this.props.currentUser.id
                }
                formatted.push(sampleBookProduct);
            });
        return formatted;
    }

    formatProductAttachmentsPayload(files) {
        const formatted = [];
        _.map(files,
            x => {
                const file = {
                    Id: 0,
                    ProductAttachmentTypeId: x.imageType.value,
                    ProductId: this.state.routeProductId ? this.state.routeProductId : 0,
                    Base64EncodedPhoto: x.base64EncodedPhoto,
                    MimeType: x.type,
                    AssetName: x.name,
                    // Content: []
                }
                formatted.push(file);
            });
        return formatted;
    }

  saveNewProduct() {
        window.scroll(0,0);
        if (!this.isFormValid()) return;
        const product = Object.assign({}, this.state.product);
        const Product = this.formatProductPayload(product);
        const WebCategories = product.webCategories && product.webCategories.length > 0
            ? _.map(product.webCategories, cat => { return Number(cat.value) }) : null;
        const SampleBookProducts = this.formatSampleBookProductsPayload(this.state.sampleBookProducts);
        const ProductAttachments = this.state.addingImage ? this.formatProductAttachmentsPayload(this.state.files) : [];
        const payload = {
            Product: Product,
            SampleBookProducts: SampleBookProducts,
            ProductAttachments: ProductAttachments,
            WebCategories: WebCategories
      };
        api.post("Product/SaveNewProduct", payload)
        .then(response => {
            if (response.data.success) {
                this.setState(prevState => ({
                    ...prevState,
                    productId: parseInt(response.data.message),
                    addingImage: false,
                    message: `Product saved.`,
                    messageFlavor: "success",
                    isMessageVisible: true,
                    isClone: false,
                    product: {
                        ...prevState.product,
                        id: parseInt(response.data.message),
                    }
                }), () => this.getProductAttachments(parseInt(response.data.message)));
            } else {
              this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }
        })
        .catch(helpers.catchHandler);
    }

    updateProduct() {
        window.scroll(0,0);
        if (!this.isFormValid()) return;
        const product = this.state.product;
        const Product = this.formatProductPayload(product);
        const WebCategories = product.webCategories && product.webCategories.length > 0
            ? _.map(product.webCategories, cat => { return Number(cat.value) }) : null;
        const SampleBookProducts = this.formatSampleBookProductsPayload(this.state.sampleBookProducts);
        const ProductAttachments = this.state.addingImage ? this.formatProductAttachmentsPayload(this.state.files) : [];
        const payload = {
            Product: Product,
            SampleBookProducts: SampleBookProducts,
            ProductAttachments: ProductAttachments,
            WebCategories: WebCategories
        };
        api.upload('put', 'Product/', payload)
        .then(response => {
            if (response.data.success) {
                // const productAttachments = this.state.productAttachments.slice();
                // const newList = _.concat(productAttachments, ProductAttachments);
                this.setState({
                    // productAttachments: newList,
                    addingImage: false,
                    message: `Product saved.`,
                    messageFlavor: "success",
                    isMessageVisible: true
                }, () => this.getProductAttachments(this.state.product.id));
            } else {
              this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }
        })
        .catch(helpers.catchHandler);
    }

    getProductAttachments(productId) {
        api.fetch(`Product/GetProductAttachments/${productId}`)
        .then(response => {
            this.setState(prevState => ({
                ...prevState,
                productAttachments: response.data
            }));
        })
        .catch(helpers.catchHandler);
    }

    handleProductTypeChange(selection) {
        let tempEmptyProduct;
        if (selection.value === constants.PRODUCT_TYPE.MISCELLANEOUS.value
          || selection.value === constants.PRODUCT_TYPE.RUG_COMPONENT.value) {
          tempEmptyProduct = Object.assign({}, emptyMiscProduct);
          tempEmptyProduct.priceUnitType = this.state.priceUnitEach;
          tempEmptyProduct.yarnBrand = this.state.unbrandedYarnBrand;
        } else if (selection.value === constants.PRODUCT_TYPE.INTL_RUG.value) {
          tempEmptyProduct  = Object.assign({}, emptyIntlRugProduct);
          tempEmptyProduct.priceUnitType = this.state.priceUnitEach;
          tempEmptyProduct.yarnBrand = this.state.unbrandedYarnBrand;
        } else {
          tempEmptyProduct = Object.assign({}, emptyProduct);
          tempEmptyProduct.yarnBrand = this.state.unbrandedYarnBrand;
          if (selection.value === constants.PRODUCT_TYPE.SQUARE_FOOT_RUG.value) {
            tempEmptyProduct.priceUnitType =
              _.find(this.state.priceUnitTypeList.slice(),
                u => { return u.value === constants.PRICE_UNIT_TYPE.SQ_FT.value; });
            // tempEmptyProduct.rugIntlCollection = _.find(this.state.rugIntlCollectionList.slice(),
            //   r => {return r.label === constants.NONE_INTERNATIONAL_RUG_COLLECTION}
            // )
          } else {
            tempEmptyProduct.priceUnitType = emptyProduct.priceUnitType;
          } 

          if (selection.value === constants.PRODUCT_TYPE.HARD_SURFACE.value) {
            tempEmptyProduct.priceUnitType = _.find(this.state.priceUnitTypeList, u => { return u.value === constants.PRICE_UNIT_TYPE.CARTON.value});
          }
          if (selection.value === constants.PRODUCT_TYPE.CARPET_TILE.value) {
            tempEmptyProduct.priceUnitType = _.find(this.state.priceUnitTypeList, u => { return u.value === constants.PRICE_UNIT_TYPE.SQ_FT.value});
          }
          if (selection.value === constants.PRODUCT_TYPE.CARPET.value) {
            tempEmptyProduct.priceUnitType = _.find(this.state.priceUnitTypeList, u => { return u.value === constants.PRICE_UNIT_TYPE.SQ_YARDS.value});
          }
        }

        this.setState({
            product: {
                ...tempEmptyProduct,
                productType: selection,
                supplier: this.state.product.supplier
            }
        });
    }

    onDismiss() {
      this.setState({
        isMessageVisible: !this.state.isMessageVisible,
        message: null,
        messageColor: null
      });
    }

    onClone() {
      api.post('Product/Clone/' + this.state.routeProductId).then(response => {
        if (response.data.success) {
          this.setState({
            // isClone: true,
            routeProductId: parseInt(response.data.message, 10),
            message: "Product cloned (and saved) - continue to edit your new product here",
            messageFlavor: "info",
            isMessageVisible: true
          }, () => {
            this.getProductToEdit(this.state.routeProductId, false);
          });
        } else {
          this.setState({
            message: "Product cloned failed: " + response.data.message,
            messageFlavor: "danger",
            isMessageVisible: true
          });
        }
      }).catch(helpers.catchHandler);
    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to="/maintainproduct" />;
        };
        const previewStyle = {
            display: 'inline',
            width: 200,
            height: 200
      };
      const SaveCancelButtons =() => {
        return <Row className="expand-md row-spacing">
          <Col className="text-right">
            <ButtonGroup className="float-right">
              {this.state.routeProductId === null || this.state.isClone ? (
                <Button
                  className="primary"
                  size="sm"
                  onClick={() => this.saveNewProduct()}
                >
                  <FontAwesomeIcon icon="save" /> Save
                </Button>
              ) : (
                <Fragment>
                  <Button
                    className="primary"
                    size="sm"
                    onClick={() => this.updateProduct()}
                  >
                    <FontAwesomeIcon icon="save" /> Update
                  </Button>
                    <Button
                        className="info"
                        size="sm"
                        onClick={this.onClone}
                    >
                      <FontAwesomeIcon icon="clone" /> Copy To New
                   </Button>
                </Fragment>
                )}
              <Button
                className="secondary"
                size="sm"
                onClick={this.onCancel}
              >
                <FontAwesomeIcon icon="reply" /> Back to Products
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

      }
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message &&
                            <Row className="mb-2">
                                <Col>
                                  <Alert className={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={() => this.onDismiss()}>{this.state.message}</Alert>
                                </Col>
                              </Row>
                        }
                        <Row>
                            <Col xs="4">
                                <h3 className="pull-left page-title">Product Administration</h3>
                            </Col>
                          <Col xs="4">
                              <h5 style={{fontWeight: "bold", fontSize: "1.5rem"}}>{this.state.product.dcName}</h5>
                          </Col>
                            <Col xs="4">{SaveCancelButtons()}</Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Nav tabs className="flex-column nav-pills">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === '1',
                                                primary: this.state.activeTab === '1'
                                            })}
                                            onClick={() => this.toggle('1')}
                                        >
                                            Product Details
                                        </NavLink>
                                    </NavItem>
                                    {(this.state.routeProductId
                                        && this.state.product.productType.value !== constants.PRODUCT_TYPE.MISCELLANEOUS.value
                                        && this.state.product.productType.value !== constants.PRODUCT_TYPE.RUG_COMPONENT.value) && (
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '2',
                                                    primary: this.state.activeTab === '2'
                                                })}
                                                onClick={() => this.toggle('2')}
                                            >
                                                {this.state.product.productType.value === constants.PRODUCT_TYPE.INTL_RUG.value
                                                    ? `Sizes, Pricing & Colors`
                                                    : `Colors & Widths`
                                                }
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {(this.state.routeProductId
                                        && this.state.product.productType.value !== constants.PRODUCT_TYPE.MISCELLANEOUS.value
                                        && this.state.product.productType.value !== constants.PRODUCT_TYPE.RUG_COMPONENT.value) && (
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '3',
                                                    primary: this.state.activeTab === '3'
                                                })}
                                                onClick={() => this.toggle('3')}
                                            >
                                                Memo Inventory
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            </Col>
                            <Col xs="10">
                                <TabContent activeTab={this.state.activeTab} className="mt-2">
                                  <TabPane tabId="1">
                                    {this.state.activeTab === "1" && (<Fragment>
                                          <Row>
                                              <Col sm="2">
                                                  <h4>Product Details</h4>
                                              </Col>
                                          </Row>
                                          <Row className="mb-3">
                                              <Col>
                                                  <Card className="p-3" style={{background: "#eee", minHeight:"275px"}}>
                                                      <Row>
                                                          <Col sm="6">
                                                              <Label className="bold-title m-0">Supplier {helpers.requiredStar()} </Label>
                                                              <Select
                                                                  closeMenuOnSelect
                                                                  value={this.state.product.supplier}
                                                                  options={this.state.suppliersList}
                                                                  onChange={this.onSupplierChange}
                                                              />
                                                          </Col>
                                                          <Col>
                                                              <Row>
                                                                  <Col sm="6">
                                                                      <Label className="bold-title m-0">Account #</Label>
                                                                      <div>{this.state.supplier.accountNumber}</div>
                                                                  </Col>
                                                              </Row>
                                                          </Col>
                                                      </Row>
                                                      <Row>
                                                          <Col xs="6">
                                                              <Row className="mt-2">
                                                                  <Col>
                                                                      <Label className="bold-title m-0">Supplier Phone</Label>
                                                                      <div>
                                                                          {/* {helpers.formatPhoneNumber(
                                                                          this.state.supplier.contact.phoneNumber ?
                                                                              this.state.supplier.contact.phoneNumber.number :
                                                                              "")} */}
                                                                        {helpers.formatPhoneNumber(this.state.supplier.supplierPhoneNo ? this.state.supplier.supplierPhoneNo : "")}
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                          </Col>
                                                      </Row>
                                                      <Row className="mb-2">
                                                          <Col>
                                                              <Row>
                                                                  <Col>
                                                                      <Label className="bold-title m-0">Agent Name</Label>
                                                                      <div>
                                                                          {/* {this.state.supplier.contact.firstName} {this.state.supplier.contact.lastName} */}
                                                                          {this.state.supplier.millAgentName}
                                                                      </div>
                                                                  </Col>
                                                                  <Col>
                                                                      <Label className="bold-title m-0">Email</Label>
                                                                      <div>
                                                                          {/* {this.state.supplier.contact.email} */}
                                                                          {this.state.supplier.millAgentEmail}
                                                                      </div>
                                                                  </Col>
                                                                  <Col>
                                                                      <Label className="bold-title m-0">Phone</Label>
                                                                      <div>
                                                                          {/* {this.state.supplier.contact.phoneNumber.number} */}
                                                                          {helpers.formatPhoneNumber(this.state.supplier.millAgentPhone ? this.state.supplier.millAgentPhone : "")}
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                          </Col>
                                                      </Row>
                                                  </Card>
                                              </Col>
                                              <Col>
                                                  <Card className="p-3" style={{background: "#eee", minHeight:"275px"}}>
                                                      <Row>
                                                          <Col xs="4">
                                                              <Label className="bold-title">Date Created</Label>
                                                          </Col>
                                                          <Col xs="8">
                                                              {this.state.product.createdAt !== null
                                                                  ? moment(this.state.product.createdAt).format('MM/DD/YY')
                                                                  : moment(Date.now()).format('MM/DD/YY')}
                                                          </Col>
                                                      </Row>
                                                      <Row>
                                                          <Col xs="4">
                                                              <Label className="bold-title">Created by:</Label>
                                                          </Col>
                                                          <Col xs="8">
                                                              {/* TODO: Needs to be fixed to get name for saved CreatedByUserId field */}
                                                              {/*{`${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`}*/}
                                                              {this.state.product.createdByUserName}
                                                          </Col>
                                                      </Row>
                                                      <Row className="mb-3">
                                                          <Col xs="4">
                                                              <Label className="bold-title">Product Type {helpers.requiredStar()}</Label>
                                                          </Col>
                                                          <Col xs="8">
                                                              <Select
                                                                  closeMenuOnSelect
                                                                  value={this.state.product.productType}
                                                                  options={this.state.productTypeList}
                                                                  onChange={this.handleProductTypeChange.bind(this)}
                                                                  isDisabled={this.state.product.id > 0}  // updating product
                                                              />
                                                          </Col>
                                                      </Row>
                                                      {this.state.product.productType.value === constants.PRODUCT_TYPE.MISCELLANEOUS.value
                                                        || this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_COMPONENT.value
                                                          ? null
                                                          : <Row className="mb-3">
                                                              <Col xs="4">
                                                                  <Label className="bold-title">Product Category {helpers.requiredStar()}</Label>
                                                              </Col>
                                                              <Col xs="8">
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.carpetProductType}
                                                                      options={this.state.carpetProductTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "carpetProductType")}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                      }
                                                      <Row>
                                                          <Col xs="4">
                                                              <Label className="bold-title">Product Status {helpers.requiredStar()}</Label>
                                                          </Col>
                                                          <Col sm="8">
                                                              <Select
                                                                  closeMenuOnSelect
                                                                  value={this.state.product.productStatus}
                                                                  options={this.state.productStatusList}
                                                                  onChange={this.handleFormSelectionChange.bind(this, "productStatus")}
                                                              />
                                                      </Col>
                                                      </Row>
                                                      <Row className="mt-3">
                                                        <Col xs="4">
                                                          <Label className="bold-title">Allow Commission? {helpers.requiredStar()}</Label>
                                                        </Col>
                                                        <Col xs="4">
                                                          <ToggleButton
                                                            className="float-left"
                                                            value={this.state.product.allowCommission}
                                                            activeLabel={"Yes"}
                                                            inactiveLabel={"No"}
                                                            onToggle={(value) => {
                                                              this.setState(prevState => ({
                                                                ...prevState,
                                                                product: {
                                                                  ...prevState.product,
                                                                  allowCommission: !value
                                                                }
                                                              }));
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>

                                                  </Card>
                                              </Col>
                                          </Row>
                                          {(this.state.product.productType.value !== constants.PRODUCT_TYPE.MISCELLANEOUS.value
                                              && this.state.product.productType.value !== constants.PRODUCT_TYPE.INTL_RUG.value
                                              && this.state.product.productType.value !== constants.PRODUCT_TYPE.RUG_COMPONENT.value)
                                              ? <Row className="mb-3">
                                                  <Col>
                                                      <Card className="p-3" style={{background: "#eee"}}>
                                                          <Row className="mb-2">
                                                              <Col xs="3">
                                                                  {/*Supplier Product Name and Number are save in DB as SupplierName and SupplierNumber*/}
                                                                  <Label className="bold-title m-0">Supplier Product Name {helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="supplierName"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.supplierName}
                                                                      maxLength="50"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">Supplier Style # {helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="supplierNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.supplierNumber}
                                                                      maxLength="10"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">DC Name {helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="name"
                                                                      name="dcName"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.dcName}
                                                                      maxLength="50"
                                                                      placeholder="DC Name"
                                                                  />
                                                              </Col>
                                                              <Col sm="3">
                                                                  <Label className="bold-title m-0">DC Style # {helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="dcNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.dcNumber}
                                                                      maxLength="10"
                                                                      placeholder="DC Style #"
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          {this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
                                                            || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value
                                                            ?   <Row className="mb-3" >
                                                                    <Col xs="3">
                                                                        <Label className="bold-title m-0">Price Units {helpers.requiredStar()}</Label>
                                                                        <Select
                                                                            closeMenuOnSelect
                                                                            value={this.state.product.priceUnitType}
                                                                            options={this.state.priceUnitTypeList}
                                                                            onChange={this.handleFormSelectionChange.bind(this, "priceUnitType")}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="3">
                                                                        <Label className="bold-title m-0">Cost {helpers.requiredStar()}</Label>
                                                                        <Input
                                                                            type="number"
                                                                            step="0.01"
                                                                            name= {
                                                                                this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
                                                                                    || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value
                                                                                ? "unitCost"
                                                                                : "rawCost"
                                                                            }
                                                                            onChange={(event) => this.updateProductState(
                                                                                event.target.name,
                                                                                event.target.value)}
                                                                            value={
                                                                                this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
                                                                                    || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value
                                                                                ? this.state.product.unitCost
                                                                                : this.state.product.rawCost}
                                                                            onBlur={(event) => this.updateCurrencyField(
                                                                                event.target.name,
                                                                                this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
                                                                                    || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value
                                                                                ? this.state.product.unitCost
                                                                                : this.state.product.rawCost)}
                                                                            min="0"
                                                                        />
                                                                    </Col>
                                                                    <Col xs="3">
                                                                        <Label className="bold-title m-0">Sale Price {helpers.requiredStar()}</Label>
                                                                        <Input
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="unitPrice"
                                                                            onChange={(event) => this.updateProductState(
                                                                                event.target.name,
                                                                                event.target.value)}
                                                                            value={this.state.product.unitPrice}
                                                                            onBlur={(event) => this.updateCurrencyField(
                                                                                event.target.name,
                                                                                this.state.product.unitPrice)}
                                                                            min="0"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            :   <React.Fragment>
                                                                    <Row>
                                                                        <Col xs="3">
                                                                            <Label className="bold-title m-0">Price Units {helpers.requiredStar()}</Label>
                                                                            <Select
                                                                                closeMenuOnSelect
                                                                                value={this.state.product.priceUnitType}
                                                                                options={this.state.priceUnitTypeList}
                                                                                onChange={this.handleFormSelectionChange.bind(this, "priceUnitType")}
                                                                                isDisabled={this.state.product.productType.value === constants.PRODUCT_TYPE.SQUARE_FOOT_RUG.value 
                                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.CARPET_TILE.value
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Label className="bold-title m-0 float-right">
                                                                                {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value ? `Box Cost` : `Net Cut Cost`} {helpers.requiredStar()}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <Input
                                                                                type="number"
                                                                                step="0.01"
                                                                                name="unitCost"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.unitCost}
                                                                                onBlur={(event) => this.updateCurrencyField(
                                                                                    event.target.name,
                                                                                    this.state.product.unitCost)}
                                                                                min={0}
                                                                            />
                                                                        </Col>
                                                                        {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                                        ?   null
                                                                        :   <React.Fragment>
                                                                                <Col>
                                                                                    <Label className="bold-title m-0 float-right">Raw Cut Cost {helpers.requiredStar()}</Label>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Input
                                                                                        type="number"
                                                                                        step="0.01"
                                                                                        name="rawCost"
                                                                                        onChange={(event) => this.updateProductState(
                                                                                            event.target.name,
                                                                                            event.target.value)}
                                                                                        value={this.state.product.rawCost}
                                                                                        onBlur={(event) => this.updateCurrencyField(
                                                                                            event.target.name,
                                                                                            this.state.product.rawCost)}
                                                                                        min="0"
                                                                                    />
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        }
                                                                        <Col>
                                                                            <Label className="bold-title m-0 float-right">
                                                                                {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value ? `Box Sell Price` : `Sale Cut Price`} {helpers.requiredStar()}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <Input
                                                                                type="number"
                                                                                step="0.01"
                                                                                name="unitPrice"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.unitPrice}
                                                                                onBlur={(event) => this.updateCurrencyField(
                                                                                    event.target.name,
                                                                                    this.state.product.unitPrice)}
                                                                                min="0"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col xs="3"></Col>
                                                                        {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                                        ?   null
                                                                        :   <React.Fragment>
                                                                                <Col>
                                                                                    <Label className="bold-title m-0 float-right">Net Roll Cost {helpers.requiredStar()}</Label>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Input
                                                                                        type="number"
                                                                                        step="0.01"
                                                                                        name="netVolumeCost"
                                                                                        onChange={(event) => this.updateProductState(
                                                                                            event.target.name,
                                                                                            event.target.value)}
                                                                                        value={this.state.product.netVolumeCost}
                                                                                        onBlur={(event) => this.updateCurrencyField(
                                                                                            event.target.name,
                                                                                            this.state.product.netVolumeCost)}
                                                                                        min="0"
                                                                                    />
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        }   
                                                                        <Col>
                                                                            <Label className="bold-title m-0 float-right">
                                                                                {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value ? `Pallet Cost` : `Raw Roll Cost`} {helpers.requiredStar()}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <Input
                                                                                type="number"
                                                                                step="0.01"
                                                                                name="volumeCost"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.volumeCost}
                                                                                onBlur={(event) => this.updateCurrencyField(
                                                                                    event.target.name,
                                                                                    this.state.product.volumeCost)}
                                                                                min="0"
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Label className="bold-title m-0 float-right">
                                                                                {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value ? `Pallet Sell Price` : `Sale Roll Price`} {helpers.requiredStar()}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <Input
                                                                                type="number"
                                                                                step="0.01"
                                                                                name="volumePrice"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.volumePrice}
                                                                                onBlur={(event) => this.updateCurrencyField(
                                                                                    event.target.name,
                                                                                    this.state.product.volumePrice)}
                                                                                min="0"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                          }
                                                          
                                                          <Row className="mb-3 p-3" style={{background: "#ccc"}}>
                                                              {/*The fields in this row are not stored in the database...*/}
                                                              <Col sm="3">
                                                                  <Row>
                                                                      <Col xs="3">
                                                                          <Label className="float-right bold-title">Use</Label>
                                                                      </Col>
                                                                      <Col xs="6">
                                                                          <Select
                                                                              closeMenuOnSelect
                                                                              value={this.state.product.useCost}
                                                                              options={[{ value: 1, label: "CUT" }, { value: 2, label: "ROLL" }]}
                                                                              onChange={this.handleFormSelectionChange.bind(this, "useCost")}
                                                                              onBlur={this.calcSpecialPriceMargin}
                                                                          />
                                                                      </Col>
                                                                      <Col xs="3" className="ml-0 pl-0">
                                                                          <Label className="float-left bold-title">Cost</Label>
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                              <Col  sm="2">
                                                                  <Label className="float-right bold-title">Spc. Price</Label>
                                                              </Col>
                                                              <Col sm="2">
                                                                  <Input
                                                                      type="number"
                                                                      name="specPrice"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.specPrice}
                                                                      onBlur={this.calcSpecialPriceMargin}
                                                                      min="0"
                                                                  />
                                                              </Col>
                                                              <Col sm="2">
                                                                  <Label className="float-right bold-title">Margin</Label>
                                                              </Col>
                                                              <Col sm="1">
                                                                  <Input
                                                                      type="text"
                                                                      name="margin"
                                                                      value={this.state.product.margin}
                                                                      readOnly
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row>
                                                              {/* <Col xs="2">
                                                                  <Label className="bold-title m-0">Collection {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.rugIntlCollection}
                                                                      options={this.state.rugIntlCollectionList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "rugIntlCollection")}
                                                                      isDisabled={this.state.product.productType.value === constants.PRODUCT_TYPE.SQUARE_FOOT_RUG.value}
                                                                  />
                                                              </Col> */}
                                                              {this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value
                                                                    ? null
                                                                    : <React.Fragment>
                                                                        <Col xs="1">
                                                                            <Label className="bold-title m-0">Roll Size {helpers.requiredStar()}</Label>
                                                                            <Input
                                                                                type="number"
                                                                                name="rollSize"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.rollSize}
                                                                                min="0"
                                                                            />
                                                                        </Col>
                                                                        <Col xs="1">
                                                                            <Label className="bold-title m-0">Run {this.state.product.productType.value == constants.PRODUCT_TYPE.CARPET.value ? helpers.requiredStar() : null}</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="run"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.run}
                                                                                maxLength="1"
                                                                            />
                                                                        </Col>
                                                                    </React.Fragment>
                                                              }
                                                              <Col sm="2">
                                                                  <Label className="bold-title m-0">Def Memo Sz</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.memoSize}
                                                                      options={this.state.memoSizeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "memoSize")}
                                                                      isClearable
                                                                  />
                                                              </Col>
                                                              <Col sm="2">
                                                                  <Label className="bold-title m-0">Memo Shelf</Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="memoShelfNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.memoShelfNumber}
                                                                      maxLength="10"
                                                                  />
                                                              </Col>
                                                              <Col xs="2">
                                                                  <Label className="bold-title">Report Memo Inventory?</Label>
                                                                  <ToggleButton
                                                                      value={this.state.product.showSampleInventory}
                                                                      activeLabel={"Yes"}
                                                                      inactiveLabel={"No"}
                                                                      onToggle={(value) => {
                                                                          this.setState(prevState => ({
                                                                              ...prevState,
                                                                              product: {
                                                                                  ...prevState.product,
                                                                                  showSampleInventory: !value
                                                                              }
                                                                          }));
                                                                      }}
                                                                  />
                                                              </Col>
                                                              {this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.CARPET_TILE.value
                                                                ?   null
                                                                :   <React.Fragment>
                                                                        <Col xs="2">
                                                                            <Label className="bold-title m-0">Repeat(text)</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="repeat"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.repeat}
                                                                                maxLength="45"
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Label className="bold-title m-0">Rept W" {helpers.requiredStar()}</Label>
                                                                            <Input
                                                                                type="number"
                                                                                name="repeatWidth"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.repeatWidth}
                                                                                step="0.001"
                                                                                min="0"
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Label className="bold-title m-0">x L" {helpers.requiredStar()}</Label>
                                                                            <Input
                                                                                type="number"
                                                                                name="repeatLength"
                                                                                onChange={(event) => this.updateProductState(
                                                                                    event.target.name,
                                                                                    event.target.value)}
                                                                                value={this.state.product.repeatLength}
                                                                                step="0.001"
                                                                                min="0"
                                                                            />
                                                                        </Col>
                                                                    </React.Fragment>
                                                              }
                                                          </Row>
                                                          <Row className="mb-2">
                                                              <Col sm="4">
                                                                  <Label className="bold-title m-0">Fiber {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.pileType}
                                                                      options={this.state.pileTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "pileType")}
                                                                  />
                                                              </Col>
                                                              {this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_PAD.value
                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                                || this.state.product.productType.value === constants.PRODUCT_TYPE.BORDER_OR_BINDING.value
                                                                    ? null
                                                                    :   <React.Fragment>
                                                                            {/* <Col sm="3">
                                                                                <Label className="bold-title m-0">Fiber Brand {helpers.requiredStar()}</Label>
                                                                                <Select
                                                                                    closeMenuOnSelect
                                                                                    value={this.state.product.yarnBrand}
                                                                                    options={this.state.yarnBrandList}
                                                                                    onChange={this.handleFormSelectionChange.bind(this, "yarnBrand")}
                                                                                />
                                                                            </Col> */}
                                                                            <Col sm="2">
                                                                                <Label className="bold-title m-0">Backing{helpers.requiredStar()}</Label>
                                                                                <Select
                                                                                    closeMenuOnSelect
                                                                                    value={this.state.product.backingType}
                                                                                    options={this.state.backingTypeList}
                                                                                    onChange={this.handleFormSelectionChange.bind(this, "backingType")}
                                                                                />
                                                                            </Col>
                                                                            <Col xs="2">
                                                                                <Label className="bold-title">Rug Is Quotable?</Label>
                                                                                <ToggleButton
                                                                                    value={this.state.product.isRugQuotable}
                                                                                    activeLabel={"Yes"}
                                                                                    inactiveLabel={"No"}
                                                                                    onToggle={(value) => {
                                                                                        this.setState(prevState => ({
                                                                                            ...prevState,
                                                                                            product: {
                                                                                                ...prevState.product,
                                                                                                isRugQuotable: !value
                                                                                            }
                                                                                        }));
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col sm="1">
                                                                                <Label className="bold-title m-0">Pile Height</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="pileHeight"
                                                                                    onChange={(event) => this.updateProductState(
                                                                                        event.target.name,
                                                                                        event.target.value)}
                                                                                    value={this.state.product.pileHeight}
                                                                                    maxLength="20"
                                                                                />
                                                                            </Col>
                                                                            <Col sm="1">
                                                                                <Label className="bold-title m-0">Face Wt</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="faceWeight"
                                                                                    onChange={(event) => this.updateProductState(
                                                                                        event.target.name,
                                                                                        event.target.value)}
                                                                                    value={this.state.product.faceWeight}
                                                                                    maxLength="6"
                                                                                />
                                                                            </Col>
                                                                        </React.Fragment>
                                                              }
                                                          </Row>
                                                          {this.state.product.productType.value === constants.PRODUCT_TYPE.CARPET_TILE.value
                                                              || this.state.product.productType.value === constants.PRODUCT_TYPE.HARD_SURFACE.value
                                                          ?   <div className="p-2" style={{backgroundColor: "#ccc"}}>
                                                                  <Row className="mb-2">
                                                                      <Col sm="4"> Edge
                                                                          <Input
                                                                              type="text"
                                                                              name="edge"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.edge}
                                                                              maxLength="50"
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2"> Item Width
                                                                          <Input
                                                                              type="text"
                                                                              name="itemWidth"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.itemWidth}
                                                                              maxLength="25"
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2"> Item Length
                                                                          <Input
                                                                              type="text"
                                                                              name="itemLength"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.itemLength}
                                                                              maxLength="25"
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2"># Per Sold Unit
                                                                          <Input
                                                                              type="text"
                                                                              name="perContainer"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.perContainer}
                                                                              maxLength="25"
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2">Ft Per Sold Unit
                                                                          <Input
                                                                              type="number"
                                                                              name="squareFeetPerCarton"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.squareFeetPerCarton}
                                                                              step="0.01"
                                                                              min="0"
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col sm="4" title={"not yet implemented"}>
                                                                          Install Methods
                                                                          <Row>
                                                                              <Col className="ml-4">
                                                                                  <Input
                                                                                      type="checkbox"
                                                                                      name="nail_selected"
                                                                                      checked={_.includes(this.state.product.installMethod, 1)}
                                                                                      onChange={(event) => this.handleCheckboxChange(1, event.target.checked)}
                                                                                  />
                                                                                  <Label>Nail</Label>
                                                                              </Col>
                                                                              <Col>
                                                                                  <Input
                                                                                      type="checkbox"
                                                                                      name="staple_selected"
                                                                                      checked={_.includes(this.state.product.installMethod, 2)}
                                                                                      onChange={(event) => this.handleCheckboxChange(2, event.target.checked)}
                                                                                  />
                                                                                  <Label>Staple</Label>
                                                                              </Col>
                                                                              <Col>
                                                                                  <Input
                                                                                      type="checkbox"
                                                                                      name="glue_selected"
                                                                                      checked={_.includes(this.state.product.installMethod, 3)}
                                                                                      onChange={(event) => this.handleCheckboxChange(3, event.target.checked)}
                                                                                  />
                                                                                  <Label>Glue</Label>
                                                                              </Col>
                                                                              <Col>
                                                                                  <Input
                                                                                      type="checkbox"
                                                                                      name="floating_selected"
                                                                                      checked={_.includes(this.state.product.installMethod, 4)}
                                                                                      onChange={(event) => this.handleCheckboxChange(4, event.target.checked)}
                                                                                  />
                                                                                  <Label>Floating</Label>
                                                                              </Col>
                                                                          </Row>
                                                                      </Col>
                                                                      <Col sm="2"> Install Grade
                                                                          <Select
                                                                              closeMenuOnSelect
                                                                              value={this.state.product.installGrade}
                                                                              options={this.state.installGradeList}
                                                                              onChange={this.handleFormSelectionChange.bind(this, "installGrade")}
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2">Ply
                                                                          <Input
                                                                              type="number"
                                                                              name="ply"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.ply}
                                                                              step="1"
                                                                              min="0"
                                                                              max="32767"
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2">Thickness
                                                                          <Input
                                                                              type="text"
                                                                              name="thickness"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.thickness}
                                                                              maxLength="5"
                                                                          />
                                                                      </Col>
                                                                      <Col sm="2"> Color & Grain
                                                                          <Select
                                                                              closeMenuOnSelect
                                                                              value={this.state.product.colorAndGrainType}
                                                                              options={this.state.colorAndGrainTypeList}
                                                                              onChange={this.handleFormSelectionChange.bind(this, "colorAndGrainType")}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                              </div>
                                                          : null
                                                          }
                                                          <Row className="mb-2">
                                                              <Col sm="4">
                                                                  <Label className="bold-title m-0">Construction Type (CS)</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.constructionType}
                                                                      options={this.state.constructionTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "constructionType")}
                                                                  />
                                                                  <Row className="mt-2">
                                                                      <Col>
                                                                          <Label className="bold-title">Basic Specs/Warranties</Label>
                                                                          <Input
                                                                              type="text"
                                                                              name="basicSpecs"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.basicSpecs}
                                                                              maxLength="200"
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row className="mt-2">
                                                                      <Col>
                                                                          <Label className="bold-title">Cleaning Instructions</Label>
                                                                          <Input
                                                                              type="textarea"
                                                                              name="cleaningComment"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.cleaningComment}
                                                                              maxLength="500"
                                                                              style={{height: "75px"}}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                              <Col sm="8">
                                                                  <Label className="bold-title m-0">Notes</Label>
                                                                  <Input
                                                                      type="textarea"
                                                                      name="comment"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.comment}
                                                                      maxLength="1000"
                                                                      style={{height: "235px"}}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                              <Col xs="4">
                                                                  <Label className="bold-title m-0">Web Category</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      isMulti={true}
                                                                      value={this.state.product.webCategories}
                                                                      options={this.state.webCategoryList}
                                                                      isClearable
                                                                      onChange={selection => {
                                                                        this.handleMultiFormSelectionChange("webCategories", selection)
                                                                      }}
                                                                  />
                                                                  <Row className="mt-3">
                                                                      <Col xs="6">
                                                                          <Label className="bold-title">Show On The Web?</Label>
                                                                      </Col>
                                                                      <Col xs="6">
                                                                          <ToggleButton
                                                                              value={this.state.product.showOnWeb}
                                                                              activeLabel={"Yes"}
                                                                              inactiveLabel={"No"}
                                                                              onToggle={(value) => {
                                                                                  this.setState(prevState => ({
                                                                                      ...prevState,
                                                                                      product: {
                                                                                          ...prevState.product,
                                                                                          showOnWeb: !value
                                                                                      }
                                                                                  }));
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  {/* <Row className="mt-3">
                                                                      <Col xs="6">
                                                                          <Label className="bold-title">Order By DC Name?</Label>
                                                                      </Col>
                                                                      <Col xs="6">
                                                                          <ToggleButton
                                                                              value={this.state.product.orderByDC}
                                                                              activeLabel={"Yes"}
                                                                              inactiveLabel={"No"}
                                                                              onToggle={(value) => {
                                                                                  this.setState(prevState => ({
                                                                                      ...prevState,
                                                                                      product: {
                                                                                          ...prevState.product,
                                                                                          orderByDC: !value
                                                                                      }
                                                                                  }));
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  </Row> */}
                                                                  {/* <Row className="mt-3">
                                                                      <Col xs="6">
                                                                          <Label className="bold-title">Rug Sample?</Label>
                                                                      </Col>
                                                                      <Col xs="6">
                                                                          <ToggleButton
                                                                              value={this.state.product.isCustomRugProduct}
                                                                              activeLabel={"Yes"}
                                                                              inactiveLabel={"No"}
                                                                              onToggle={(value) => {
                                                                                  this.setState(prevState => ({
                                                                                      ...prevState,
                                                                                      product: {
                                                                                          ...prevState.product,
                                                                                          isCustomRugProduct: !value
                                                                                      }
                                                                                  }));
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  </Row> */}
                                                                  {/* <Row className="mt-3">
                                                                      <Col xs="6">
                                                                          <Label className="bold-title">Salable as Standard Custom Rug?</Label>
                                                                      </Col>
                                                                      <Col xs="6">
                                                                          <ToggleButton
                                                                              value={this.state.product.salableAsStandardCustomRug}
                                                                              activeLabel={"Yes"}
                                                                              inactiveLabel={"No"}
                                                                              onToggle={(value) => {
                                                                                  this.setState(prevState => ({
                                                                                      ...prevState,
                                                                                      product: {
                                                                                          ...prevState.product,
                                                                                          salableAsStandardCustomRug: !value
                                                                                      }
                                                                                  }));
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  </Row> */}
                                                              </Col>
                                                              <Col sm="8">
                                                                  <Label className="bold-title m-0">Web Description</Label>
                                                                  <Input
                                                                      type="textarea"
                                                                      name="webProductDescription"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.webProductDescription}
                                                                      maxLength="1000"
                                                                      style={{height: "150px"}}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-3">
                                                              <Col sm="6">
                                                                  <Row className="mb-2">
                                                                      <Col>
                                                                          <Label className="bold-title m-0">This product is found in the following books:</Label>
                                                                          <Select
                                                                              closeMenuOnSelect
                                                                              value={this.state.selectedSampleBook}
                                                                              options={this.state.sampleBookList}
                                                                              onChange={(option) => this.onBookSelect(option)}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col>
                                                                          <Card>
                                                                              <CardBody>
                                                                                  <Row>
                                                                                      <Col>
                                                                                          <Label className="bold-title m-0">Books Selected:</Label>

                                                                                      </Col>
                                                                                  </Row>
                                                                                  {this.state.sampleBookProducts.length > 0 &&
                                                                                      this.state.sampleBookProducts.map(sb => (
                                                                                          <Row className="row-spacing" key={sb.value ? sb.value : sb.id}>
                                                                                              <Col>
                                                                                                  {sb.label ? sb.label : sb.sampleBookName}
                                                                                              </Col>
                                                                                              <Col>
                                                                                                  <Button
                                                                                                      className="float-right danger btn-outline-secondary"
                                                                                                      size="sm"
                                                                                                      onClick={e => this.removeBook(sb)}
                                                                                                  >
                                                                                                      <FontAwesomeIcon icon="trash" />
                                                                                                  </Button>
                                                                                              </Col>
                                                                                          </Row>
                                                                                  ))}
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                              <Col>
                                                                  <Row className="mb-2">
                                                                    <Col>
                                                                      <Label className="bold-title m-0">Product Images</Label>
                                                                      <div>
                                                                        <Button
                                                                          className={this.state.addingImage ? "danger" : "success"}
                                                                          size="sm"
                                                                          id="dropzone-toggler"
                                                                          onClick={this.toggleImages}
                                                                        >
                                                                          {this.state.addingImage
                                                                            ? <FontAwesomeIcon icon="minus" />
                                                                            : <FontAwesomeIcon icon="plus" />
                                                                          } Images
                                                                        </Button>
                                                                      </div>
                                                                    </Col>
                                                                  </Row>
                                                                  <Row className="mb-2">
                                                                      <Col>
                                                                          {this.state.addingImage && (
                                                                              <div>
                                                                                  {this.state.errorMessage ?
                                                                                      <Alert className="warning">
                                                                                          {this.state.errorMessage}
                                                                                      </Alert>
                                                                                      : null
                                                                                  }
                                                                                  <DropzoneUploader
                                                                                    defaultAttachmentType={constants.ATTACHMENT_TYPE.DETAILED_SPECS}
                                                                                    filesCallback={(files) => this.setState({files: files})}
                                                                                    productAttachmentTypeList={this.state.productAttachmentTypeList}
                                                                                  />
                                                                              </div>
                                                                          )}
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col>
                                                                          <Row>
                                                                              {this.state.productAttachments.map(pa => (
                                                                                  <Col key={pa.id}>
                                                                                      <Row>
                                                                                          <Col>
                                                                                              <Label>{pa.productAttachmentTypeName}</Label>{" "}
                                                                                              <Button
                                                                                                  className="danger btn-outline-secondary"
                                                                                                  size="sm"
                                                                                                  onClick={() => this.removeProductAttachment(pa)}
                                                                                              >
                                                                                                  <FontAwesomeIcon icon="trash" />
                                                                                              </Button>
                                                                                          </Col>
                                                                                      </Row>
                                                                                      <Row>
                                                                                        <Col>
                                                                                        {pa.productAttachmentTypeName === 'Detailed Specs'
                                                                                          ? (<Button title="View PDF" key={pa.id} color="danger"
                                                                                              onClick={() => window.open(pa.url, "_blank")}
                                                                                            >
                                                                                              <FontAwesomeIcon icon='file-pdf' size="3x" />
                                                                                            </Button>)
                                                                                          : (<img alt="Preview" key={pa.id}
                                                                                              src={pa.url} style={previewStyle}
                                                                                              onClick={() => window.open(pa.url, "_blank")}
                                                                                            />)
                                                                                        }
                                                                                        </Col>
                                                                                      </Row>
                                                                                  </Col>
                                                                              ))}
                                                                          </Row>
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                              {/* <Col sm="3">Warranties {helpers.requiredStar()}
                                                                  <Input
                                                                      type="text"
                                                                      name="warranties"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.warranties}
                                                                      maxLength="100"
                                                                  />
                                                              </Col> */}
                                                          </Row>
                                                      </Card>
                                                  </Col>
                                              </Row>
                                          : null}
                                          {this.state.product.productType.value === constants.PRODUCT_TYPE.MISCELLANEOUS.value
                                            || this.state.product.productType.value === constants.PRODUCT_TYPE.RUG_COMPONENT.value
                                              ? <Row className="mb-3">
                                                  <Col>
                                                      <Card className="p-3" style={{background: "#eee"}}>
                                                          <Row className="mb-2">
                                                              <Col xs="3">
                                                                  {/*Supplier Product Name and Number are save in DB as SupplierName and SupplierNumber*/}
                                                                  <Label className="bold-title m-0">Supplier Product Name {helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="supplierName"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.supplierName}
                                                                      maxLength="50"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">Supplier Style #{helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="supplierNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.supplierNumber}
                                                                      maxLength="10"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">DC Name{helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="name"
                                                                      name="dcName"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.dcName}
                                                                      maxLength="50"
                                                                      placeholder="DC Name"
                                                                  />
                                                              </Col>
                                                              <Col sm="3">
                                                                  <Label className="bold-title m-0">DC Style #{helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="dcNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.dcNumber}
                                                                      maxLength="10"
                                                                      placeholder="DC Style #"
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">Price Units {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.priceUnitType}
                                                                      options={this.state.priceUnitTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "priceUnitType")}
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">Cost {helpers.requiredStar()}</Label>
                                                                  <Input
                                                                      type="number"
                                                                      step="0.01"
                                                                      name="unitCost"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.unitCost}
                                                                      onBlur={(event) => this.updateCurrencyField(
                                                                          event.target.name,
                                                                          this.state.product.unitCost)}
                                                                      min="0"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">Sale Price {helpers.requiredStar()}</Label>
                                                                  <Input
                                                                      type="number"
                                                                      step="0.01"
                                                                      name="unitPrice"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.unitPrice}
                                                                      onBlur={(event) => this.updateCurrencyField(
                                                                          event.target.name,
                                                                          this.state.product.unitPrice)}
                                                                      min="0"
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                              <Col sm="9">
                                                                  <Label className="bold-title m-0">Notes</Label>
                                                                  <Input
                                                                      type="textarea"
                                                                      name="comment"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.comment}
                                                                      maxLength="1000"
                                                                      style={{height: "195px"}}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                      </Card>
                                                  </Col>
                                              </Row>
                                          : null}
                                          {this.state.product.productType.value === constants.PRODUCT_TYPE.INTL_RUG.value
                                              ? <Row className="mb-3">
                                                  <Col>
                                                      <Card className="p-3" style={{background: "#eee"}}>
                                                          <Row className="mb-2">
                                                              <Col xs="3">
                                                                  {/*Supplier Product Name and Number are save in DB as SupplierName and SupplierNumber*/}
                                                                  <Label className="bold-title m-0">Supplier Product Name {helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="supplierName"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.supplierName}
                                                                      maxLength="50"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">Supplier Style #{helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="supplierNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.supplierNumber}
                                                                      maxLength="10"
                                                                  />
                                                              </Col>
                                                              <Col xs="3">
                                                                  <Label className="bold-title m-0">DC Name{helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="name"
                                                                      name="dcName"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.dcName}
                                                                      maxLength="50"
                                                                      placeholder="DC Name"
                                                                  />
                                                              </Col>
                                                              <Col sm="3">
                                                                  <Label className="bold-title m-0">DC Style #{helpers.requiredStar()} </Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="dcNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.dcNumber}
                                                                      maxLength="10"
                                                                      placeholder="DC Style #"
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row>
                                                              {/* <Col xs="2">
                                                                  <Label className="bold-title m-0">Collection {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.rugIntlCollection}
                                                                      options={this.state.rugIntlCollectionList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "rugIntlCollection")}
                                                                  />
                                                              </Col> */}
                                                              <Col sm="4">
                                                                  <Label className="bold-title m-0">Fiber {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.pileType}
                                                                      options={this.state.pileTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "pileType")}
                                                                  />
                                                              </Col>
                                                              {/* <Col sm="2">
                                                                  <Label className="bold-title m-0">Rug Shape {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.rugShape}
                                                                      options={this.state.rugShapeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "rugShape")}
                                                                  />
                                                              </Col> */}
                                                              <Col xs="2">
                                                                  <Label className="bold-title m-0">Price Units {helpers.requiredStar()}</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.priceUnitType}
                                                                      options={this.state.priceUnitTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "priceUnitType")}
                                                                  />
                                                              </Col>
                                                              <Col sm="2">
                                                                  <Label className="bold-title m-0">Def Memo Sz</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.memoSize}
                                                                      options={this.state.memoSizeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "memoSize")}
                                                                  />
                                                              </Col>
                                                              <Col sm="2">
                                                                  <Label className="bold-title m-0">Memo Shelf</Label>
                                                                  <Input
                                                                      type="text"
                                                                      name="memoShelfNumber"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.memoShelfNumber}
                                                                      maxLength="10"
                                                                  />
                                                              </Col>
                                                              <Col xs="2">
                                                                  <Label className="bold-title">Report Memo Inventory?</Label>
                                                                  <ToggleButton
                                                                      value={this.state.product.showSampleInventory}
                                                                      activeLabel={"Yes"}
                                                                      inactiveLabel={"No"}
                                                                      onToggle={(value) => {
                                                                          this.setState(prevState => ({
                                                                              ...prevState,
                                                                              product: {
                                                                                  ...prevState.product,
                                                                                  showSampleInventory: !value
                                                                              }
                                                                          }));
                                                                      }}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                              <Col sm="4">
                                                                  <Label className="bold-title m-0">Construction Type (CS)</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      value={this.state.product.constructionType}
                                                                      options={this.state.constructionTypeList}
                                                                      onChange={this.handleFormSelectionChange.bind(this, "constructionType")}
                                                                  />
                                                                  <Row className="mt-2">
                                                                      <Col>
                                                                          <Label className="bold-title">Basic Specs/Warranties</Label>
                                                                          <Input
                                                                              type="text"
                                                                              name="basicSpecs"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.basicSpecs}
                                                                              maxLength="200"
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row className="mt-2">
                                                                      <Col>
                                                                          <Label className="bold-title">Cleaning Instructions</Label>
                                                                          <Input
                                                                              type="textarea"
                                                                              name="cleaningComment"
                                                                              onChange={(event) => this.updateProductState(
                                                                                  event.target.name,
                                                                                  event.target.value)}
                                                                              value={this.state.product.cleaningComment}
                                                                              maxLength="500"
                                                                              style={{height: "75px"}}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                              <Col sm="8">
                                                                  <Label className="bold-title m-0">Notes</Label>
                                                                  <Input
                                                                      type="textarea"
                                                                      name="comment"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.comment}
                                                                      maxLength="1000"
                                                                      style={{height: "235px"}}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                              <Col xs="4">
                                                                  <Label className="bold-title m-0">Web Category</Label>
                                                                  <Select
                                                                      closeMenuOnSelect
                                                                      isMulti={true}
                                                                      value={this.state.product.webCategories}
                                                                      options={this.state.webCategoryList}
                                                                      isClearable
                                                                      onChange={selection => {
                                                                        this.handleMultiFormSelectionChange("webCategories", selection)
                                                                      }}
                                                                  />
                                                                  <Row className="mt-3">
                                                                      <Col xs="6">
                                                                          <Label className="bold-title">Show On The Web?</Label>
                                                                      </Col>
                                                                      <Col xs="6">
                                                                          <ToggleButton
                                                                              value={this.state.product.showOnWeb}
                                                                              activeLabel={"Yes"}
                                                                              inactiveLabel={"No"}
                                                                              onToggle={(value) => {
                                                                                  this.setState(prevState => ({
                                                                                      ...prevState,
                                                                                      product: {
                                                                                          ...prevState.product,
                                                                                          showOnWeb: !value
                                                                                      }
                                                                                  }));
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                              <Col sm="8">
                                                                  <Label className="bold-title m-0">Web Description</Label>
                                                                  <Input
                                                                      type="textarea"
                                                                      name="webProductDescription"
                                                                      onChange={(event) => this.updateProductState(
                                                                          event.target.name,
                                                                          event.target.value)}
                                                                      value={this.state.product.webProductDescription}
                                                                      maxLength="1000"
                                                                      style={{height: "150px"}}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Row className="mb-3">
                                                              <Col sm="6">
                                                                  <Row className="mb-2">
                                                                      <Col>
                                                                          <Label className="bold-title m-0">This product is found in the following books:</Label>
                                                                          <Select
                                                                              closeMenuOnSelect
                                                                              value={this.state.selectedSampleBook}
                                                                              options={this.state.sampleBookList}
                                                                              onChange={(option) => this.onBookSelect(option)}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col>
                                                                          <Card>
                                                                              <CardBody>
                                                                                  <Row>
                                                                                      <Col>
                                                                                          <Label className="bold-title m-0">Books Selected:</Label>

                                                                                      </Col>
                                                                                  </Row>
                                                                                  {this.state.sampleBookProducts.length > 0 &&
                                                                                      this.state.sampleBookProducts.map(sb => (
                                                                                          <Row className="row-spacing" key={sb.value ? sb.value : sb.id}>
                                                                                              <Col>
                                                                                                  {sb.label ? sb.label : sb.sampleBookName}
                                                                                              </Col>
                                                                                              <Col>
                                                                                                  <Button
                                                                                                      className="float-right danger btn-outline-secondary"
                                                                                                      size="sm"
                                                                                                      onClick={e => this.removeBook(sb)}
                                                                                                  >
                                                                                                      <FontAwesomeIcon icon="trash" />
                                                                                                  </Button>
                                                                                              </Col>
                                                                                          </Row>
                                                                                  ))}
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                              <Col>
                                                                  <Row className="mb-2">
                                                                      <Col>
                                                                          <Label className="bold-title m-0">Product Images</Label>
                                                                          <div>
                                                                              <Button
                                                                                  className={this.state.addingImage ? "danger" : "success"}
                                                                                  size="sm"
                                                                                  id="dropzone-toggler"
                                                                                  onClick={this.toggleImages}
                                                                              >
                                                                                  {this.state.addingImage
                                                                                          ? <FontAwesomeIcon icon="minus" />
                                                                                          : <FontAwesomeIcon icon="plus" />
                                                                                      } Images
                                                                              </Button>
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row className="mb-2">
                                                                      <Col>
                                                                          {this.state.addingImage && (
                                                                              <div>
                                                                                  {this.state.errorMessage ?
                                                                                      <Alert className="warning">
                                                                                          {this.state.errorMessage}
                                                                                      </Alert>
                                                                                      : null
                                                                                  }
                                                                                  <DropzoneUploader
                                                                                    defaultAttachmentType={constants.ATTACHMENT_TYPE.DETAILED_SPECS}
                                                                                    filesCallback={(files) => this.setState({ files: files })}
                                                                                    productAttachmentTypeList={this.state.productAttachmentTypeList}
                                                                                  />
                                                                              </div>
                                                                          )}
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col>
                                                                          <Row>
                                                                              {this.state.productAttachments.map(pa => (
                                                                                  <Col key={pa.id}>
                                                                                      <Row>
                                                                                          <Col>
                                                                                              <Label>{pa.productAttachmentTypeName}</Label>{" "}
                                                                                              <Button
                                                                                                  className="danger btn-outline-secondary"
                                                                                                  size="sm"
                                                                                                  onClick={() => this.removeProductAttachment(pa)}
                                                                                              >
                                                                                                  <FontAwesomeIcon icon="trash" />
                                                                                              </Button>
                                                                                          </Col>
                                                                                      </Row>
                                                                                      <Row>
                                                                                          <Col>
                                                                                              <img
                                                                                                  alt="Preview"
                                                                                                  key={pa.id}
                                                                                                  src={pa.url}
                                                                                                  style={previewStyle}
                                                                                                  onClick={() => window.open(pa.url, "_blank")}
                                                                                              />
                                                                                          </Col>
                                                                                      </Row>
                                                                                  </Col>
                                                                              ))}
                                                                          </Row>
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                          </Row>
                                                      </Card>
                                                  </Col>
                                              </Row>
                                          : null}

                                        {SaveCancelButtons()}
                                      </Fragment>)}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {this.state.activeTab === "2" && this.state.routeProductId && (
                                            <EditProductColorsWidths
                                                productId={this.state.routeProductId}
                                                // colorAndGrainTypeList={this.state.colorAndGrainTypeList}
                                                colorFamilyList={this.state.colorFamilyList}
                                                widthsList={this.state.widthsList}
                                                // tradeSizesList={this.state.tradeSizesList}
                                                productType={this.state.product.productType.label}
                                                productColors={this.state.productColors}
                                                productWidths={this.state.productWidths}
                                                rugIntlSizes={this.state.rugIntlSizes}
                                                productAttachmentTypeList={this.state.productAttachmentTypeList}
                                                refresh={this.getProductToEdit}
                                            />
                                        )}
                                    </TabPane>
                                    <TabPane tabId="3">
                                      {this.state.activeTab === "3" &&
                                        <Row>
                                          <Col sm="12">
                                            <EditProductMemoInventory
                                              memoSizeList={this.state.memoSizeList}
                                              productId={this.state.routeProductId}
                                              memoShelfNumber={this.state.product.memoShelfNumber}
                                              productColors={this.state.productColors}
                                              productMemoSizes={this.state.productMemoSizes}
                                              refresh={this.getProductToEdit}
                                              defaultMemoSizeName={this.state.product && this.state.product.memoSize 
                                                  ? this.state.product.memoSize.label
                                                  : null
                                                }
                                            />
                                          </Col>
                                        </Row>
                                      }
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

