import React, { Component, Fragment } from 'react';
import {
    Button,
    Col,
    Row,
    Container,
    Table,
    ButtonGroup,
    Alert,
    Input,
    Collapse,
    Card,
    CardBody,
    Label,
    Modal,
    ModalBody
} from 'reactstrap';
import {api, helpers, constants} from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import _ from "lodash";
import { DropzoneUploader } from '../components';
import { Redirect } from 'react-router-dom';
import MaintainProduct from './MaintainProduct';

const WIDTH_PRODUCT_TYPES = ["Carpet", "Rug", "Miscellaneous", "Rug Pad", "Rug Component", "Carpet Tile", "Sample"];

const emptyProductColor = {
    productId: null,
    colorFamily: null,
    name: "",
    number: "",
    seq: 0
};

const emptyRugIntlSize = {
    dsc: "",
    dscNumeric: null,
    cost: "",
    price: "",
    seq: ""
};

export default class EditProductColorsWidths extends Component {
    constructor(props) {
        super(props);
        this.state = {
          message: null,
          messageFlavor: null,
          backdrop: true,
          colorFamilyList: props.colorFamilyList,
          widthsList: props.widthsList,
          // tradeSizesList: props.tradeSizesList,
          showEdit: false,
          addingRugIntlSize: false,
          addingProductColor: false,
          newProductColor: emptyProductColor,
          productId: props.productId,
          productColors: _.map(_.filter(props.productColors, pc => !pc.deactivatedAt), pc => ({...pc, editing: false})),
          productWidths: props.productWidths,
          rugIntlSizes: _.sortBy(props.rugIntlSizes, x=> Number(x.seq)),
          selectedProductWidths: [],
          productType: props.productType,
          newRugIntlSize: emptyRugIntlSize,
          files: [],
          addingImage: false,
          productColorImages: [],
          showCopyRugPricesModal: false
        };
        this.getProductColors = this.getProductColors.bind(this);
        this.getProductWidths = this.getProductWidths.bind(this);
        this.toggleAddRugIntlSize = this.toggleAddRugIntlSize.bind(this);
        this.toggleCopyRugPricesModal = this.toggleCopyRugPricesModal.bind(this);
        this.toggleAddProductColor = this.toggleAddProductColor.bind(this);
        this.updateProductColorState = this.updateProductColorState.bind(this);
        this.addSelectedWidths = this.addSelectedWidths.bind(this);
        this.saveNewProductWidths = this.saveNewProductWidths.bind(this);
        this.saveNewRugIntlSize = this.saveNewRugIntlSize.bind(this);
        this.updateRugIntlSize = this.updateRugIntlSize.bind(this);
        this.isProductColorFormValid = this.isProductColorFormValid.bind(this);
        this.isRugIntlSizeFormValid = this.isRugIntlSizeFormValid.bind(this);
        this.saveNewProductColor = this.saveNewProductColor.bind(this);
        this.updateProductColor = this.updateProductColor.bind(this);
        this.setFirst = this.setFirst.bind(this);
        this.toggleImages = this.toggleImages.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCopyRugPrices = this.onCopyRugPrices.bind(this);
    }

    getProductWidths(productId) {
        api.fetch(`Product/GetProductWidths/${productId}`)
        .then(response => {
            this.setState({
                productWidths: response.data
            });
        })
        .catch(helpers.catchHandler);
    }

    getRugIntlSizes(productId) {
        api.fetch(`Product/GetRugIntlSizes/${productId}`)
        .then(response => {
            this.setState(prevState => ({
                ...prevState,
                rugIntlSizes: _.map(response.data, item => {
                    return {
                        ...item,
                        cost: _.round(item.cost, 2).toFixed(2),
                        price: _.round(item.price, 2).toFixed(2),
                        seq: item.seq.toString()
                    }
                })
            }));
        })
        .catch(helpers.catchHandler);
    }

    getProductColors(productId) {
      api.fetch(`Product/GetProductColors/${productId}`).then(response => {
        this.setState(prevState => ({
          ...prevState,
          productColors: _.filter(response.data, pc => !pc.deactivatedAt)
        }));
      }).catch(helpers.catchHandler);
    }

    toggleAddProductColor() {
        this.setState({
            addingProductColor: !this.state.addingProductColor,
            newProductColor: emptyProductColor,
            message: null,
            messageFlavor: null
        });
    }

    addSelectedWidths(selections) {
        this.setState({
            selectedProductWidths: selections
        });
    }

    updateProductColorState(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            newProductColor: {
                ...prevState.newProductColor,
                [fieldName]: value
            }
        }));
    }

    handleColorSelects(elementName, selection) {
        this.setState(prevState => ({
            ...prevState,
            newProductColor: {
                ...prevState.newProductColor,
                [elementName]: {
                    value: selection.value,
                    label: selection.label
                }
            }
        }));
    }

    onEditProductColor(productColorId) {
      const indexToEdit = _.findIndex(this.state.productColors, {id: productColorId});
      let tempList = this.state.productColors.slice();
      tempList[indexToEdit].pristine = Object.assign({}, tempList[indexToEdit]);
      tempList[indexToEdit].isEditing = true;
      this.setState({
        productColors: tempList,
        productColorImages: tempList[indexToEdit].productColorImages
      });
    }

    onCancelEditProductColor(productColorId) {
      const indexToEdit = _.findIndex(this.state.productColors, {id: productColorId});
      let tempList = this.state.productColors.slice();
      tempList[indexToEdit] = tempList[indexToEdit].pristine;
      tempList[indexToEdit].productColorImages = this.state.productColorImages.slice();  // needed in case an image is removed while editing
      this.setState({
        productColors: tempList, 
        message: null,
        messageFlavor: null, 
        addingImage: false,
        productColorImages: []
      });
    }

    editProductColorState(fieldName, value, productColorId) {
      let productColors = this.state.productColors.slice();
      const idx = _.findIndex(productColors, x => x.id === productColorId);
      productColors[idx][fieldName] = value;
      this.setState({ productColors: productColors });
    }

    editSelectedColorFamily(option, productColorId) {
      let productColors = this.state.productColors.slice();
      const idx = _.findIndex(productColors, x => x.id === productColorId);
      productColors[idx].colorFamilyId = option.value;
      productColors[idx].colorFamilyName = option.label;
      this.setState({ productColors: productColors });
    }

    toggleImages() {
      this.setState({ addingImage: !this.state.addingImage });
    }

    setFirst(productColorId) {
        api.post(`Product/SetFirstProductColor/${productColorId}`).then(response => {
            if (response.data.success) {
                this.getProductColors(this.state.productId);
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }
        })
        .catch(helpers.catchHandler);
    }

    removeProductColorImage(image, productColorId) {
        if (!window.confirm('This is a permanent delete.  Are you sure?')) return;
        const productColorImages = this.state.productColorImages.slice();
        const filtered = _.filter(productColorImages, x => x.id !== image.id);
        api.post(`Product/DeleteProductColorImage/${image.id}`).then(response => {
                if (response.data.success) {
                    const indexToEdit = _.findIndex(this.state.productColors, {id: productColorId});
                    let tempList = this.state.productColors.slice();
                    tempList[indexToEdit].productColorImages = filtered;
                    this.setState({
                        productColors: tempList,
                        productColorImages: filtered
                    });
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }
            })
            .catch(helpers.catchHandler);
        return null;
    }

    formatProductColorImagesPayload(files, productColorId = null) {
        const formatted = [];
        _.map(files,
            x => {
                const file = {
                    Id: 0,
                    ProductAttachmentTypeId: x.imageType.value,
                    // ProductId: 0,
                    ProductColorId: productColorId ? productColorId : 0,
                    Base64EncodedPhoto: x.base64EncodedPhoto,
                    MimeType: x.type,
                    AssetName: x.name,
                    // Content: []
                }
                formatted.push(file);
            });
        return formatted;
    }

    isProductColorFormValid(item) {
        const warnings = [];
        if (!item.Name) {
            warnings.push("Color Name is required.");
        }
        //if (!item.Number) {
        //    warnings.push("Color Number is required.");
        //}
        // if (!item.Seq) {
        //     warnings.push("Sequence is required.");
        // }
        // if (item.Seq > 32767  || item.Seq < -32768)  {
        //     warnings.push("Sequence must be less than 32767 and greater than -32768.");
        // }
        if (_.some(this.state.files, file => !file.imageType.value)) {
            warnings.push("Image Type required on all images to be uploaded.")
        }
        if (warnings.length) {
            this.setState({ 
                message: warnings.join(' '),
                messageFlavor: "danger" 
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    saveNewProductColor() {
        window.scroll(0,0);
        const color = this.state.newProductColor;
        const ProductColorImages = this.state.addingImage ? this.formatProductColorImagesPayload(this.state.files) : [];
        const ProductColor = {
            Id: 0,
            ProductId: this.state.productId,
            ColorFamilyId: color.colorFamily ? color.colorFamily.value : null,
            Name: color.name,
            Number: color.number,
            Seq: 0 // color.seq
        };
        if (!this.isProductColorFormValid(ProductColor)) return;
        // ProductColor.Seq = Number(color.seq);
        api.post("Product/SaveNewProductColor", {
            ProductColor: ProductColor,
            ProductColorImages: ProductColorImages
        }).then(response => {
            if (response.data.success) {
                this.setState({
                    files: [],
                }, () => {
                    this.setState({
                        addingProductColor: !this.state.addingProductColor,
                        newProductColor: emptyProductColor,
                        message: null,
                        messageFlavor: null
                    }, () => this.getProductColors(this.state.productId));
                    
                })
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        })
        .catch(helpers.catchHandler);
    }

    updateProductColor(color) {
        window.scroll(0,0);
        const ProductColorImages = this.state.addingImage ? this.formatProductColorImagesPayload(this.state.files, color.id) : [];
        const ProductColor = {
            Id: color.id,
            ProductId: this.state.productId,
            ColorFamilyId: color.colorFamilyId,
            Name: color.name,
            Number: color.number,
            Seq: 0 // color.seq
        };
        if (!this.isProductColorFormValid(ProductColor)) return;
        // ProductColor.Seq = Number(color.seq);
        const payload = {
            ProductColor: ProductColor,
            ProductColorImages: ProductColorImages
        };
        api.post("Product/UpdateProductColor", payload).then(response => {
            if (response.data.success) {
                this.setState({
                    files: [],
                    addingImage: false
                }, () => {
                    this.getProductColors(this.state.productId);
                })
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }    
        })
        .catch(helpers.catchHandler);
    }

    handleDeleteProductColor(id) {
        if (!window.confirm('Are you sure you want to delete this color?')) return;
        api
            .delete(`Product/DeleteProductColor/${id}`)
            .then(response => {
                if (response.data.success) { 
                    this.getProductColors(this.state.productId)
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }  
            }) 
            .catch(helpers.catchHandler);
    }

    handleReviveProductColor(id) {
        api
            .post(`Product/UndeleteProductColor/${id}`)
            .then(response => {
                if (response.data.success) { 
                    this.getProductColors(this.state.productId)
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }  
            }) 
            .catch(helpers.catchHandler);
    }

    saveNewProductWidths() {
        const selectedProductWidths = this.state.selectedProductWidths.slice();
        _.forEach(selectedProductWidths,
            x => {
                const alreadyExists = _.find(this.state.productWidths, w => w.widthName === x.label);
                if (alreadyExists) {
                    return;
                } else {
                    const data = {
                        Id: 0,
                        ProductId: this.state.productId,
                        WidthId: x.value,
                        WidthName: x.label
                    };
                    api
                        .post("Product/SaveNewProductWidth", data)
                        .then(response => {
                            if (response.data.success) {
                                this.getProductWidths(this.state.productId);
                            } else {
                                this.setState({ messageFlavor: "danger", message: response.data.message });
                            }  
                        })
                        .catch(helpers.catchHandler);
                }
            });
    }

    deleteProductWidth(productWidthId) {
        const productId = this.state.productId;
        api
            .delete(`Product/DeleteProductWidth/${productWidthId}`)
            .then(response => {
                this.getProductWidths(productId);
            })
            .catch(helpers.catchHandler);
    }

    toggleAddRugIntlSize() {
        this.setState({
            addingRugIntlSize: !this.state.addingRugIntlSize,
            newRugIntlSize: emptyRugIntlSize,
            message: null,
            messageFlavor: null
        });
    }

    toggleCopyRugPricesModal() {
        this.setState({showCopyRugPricesModal: !this.state.showCopyRugPricesModal})
    }


    updateRugIntlSizeState(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            newRugIntlSize: {
                ...prevState.newRugIntlSize,
                [fieldName]: value
            }
        }));
    }

    handleRugIntlSizeSelects(elementName, selection) {
        this.setState(prevState => ({
            ...prevState,
            newRugIntlSize: {
                ...prevState.newRugIntlSize,
                [elementName]: {
                    value: selection.value,
                    label: selection.label
                }
            }
        }));
    }

    onEditRugIntlSize(rugIntlSizeId) {
        const indexToEdit = _.findIndex(this.state.rugIntlSizes, {id: rugIntlSizeId});
        let tempList = this.state.rugIntlSizes.slice();
        const snapshotItem = Object.assign({}, tempList[indexToEdit]);
        tempList[indexToEdit].isEditing = true;
        tempList[indexToEdit].pristine = snapshotItem;
        this.setState({
            rugIntlSizes: tempList
        });
    }

    onCancelEditRugIntlSize(rugIntlSizeId) {
        const indexToEdit = _.findIndex(this.state.rugIntlSizes, {id: rugIntlSizeId});
        let tempList = this.state.rugIntlSizes.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
            rugIntlSizes: tempList, 
            message: null,
            messageFlavor: null
        });
    }

    editRugIntlSizeState(fieldName, value, rugIntlSizeId) {
        const rugIntlSizes = this.state.rugIntlSizes.slice();
        const size = _.find(rugIntlSizes, x => x.id === rugIntlSizeId);
        size[fieldName] = value;
        this.setState({
            rugIntlSizes: rugIntlSizes
        });
    }

    isRugIntlSizeFormValid(item) {
        const warnings = [];
        if (!item.Dsc) {
            warnings.push("Size Description is required.");
        }
        if (!item.Cost) {
            warnings.push("Net Cost is required.");
        }
        if (!item.Price) {
            warnings.push("Sale Price is required.");
        }
        // if (!item.TradeSizeId) {
        //     warnings.push("Trade Size is required.");
        // }
        if (!item.Seq) {
            warnings.push("Sequence is required.");
        }
        if (item.Seq > 32767  || item.Seq < -32768)  {
            warnings.push("Sequence must be less than 32767 and greater than -32768.");
        }
        if (warnings.length) {
            this.setState({ 
                message: warnings.join(' '),
                messageFlavor: "danger" 
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    saveNewRugIntlSize() {
        window.scroll(0,0);
        const size = this.state.newRugIntlSize;
        const RugIntlSize = {
            Id: 0,
            ProductId: this.state.productId,
            Dsc: size.dsc,
            DscNumeric: null,
            Cost: size.cost,
            Price: size.price,
            // TradeSizeId: size.tradeSizeId ? size.tradeSizeId : null,  // no longer used
            Seq: size.seq
        };
        if (!this.isRugIntlSizeFormValid(RugIntlSize)) return;
        RugIntlSize.Cost = Number(size.cost); 
        RugIntlSize.Price = Number(size.price); 
        RugIntlSize.Seq = Number(size.seq); 
        api.post("Product/SaveNewRugIntlSize", RugIntlSize)
        .then(response => {
            if (response.data.success) {
                    // this.toggleAddRugIntlSize();
                    this.setState({
                        addingRugIntlSize: !this.state.addingRugIntlSize,
                        newRugIntlSize: emptyRugIntlSize,
                        message: null,
                        messageFlavor: null
                    }, () => this.getRugIntlSizes(this.state.productId));
                    ;
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        })
        .catch(helpers.catchHandler);
    }

    updateRugIntlSize(size) {
        window.scroll(0,0);
        const RugIntlSize = {
            Id: size.id,
            ProductId: this.state.productId,
            Dsc: size.dsc,
            DscNumeric: null,
            Cost: size.cost,
            Price: size.price,
            TradeSizeId: size.tradeSizeId ? size.tradeSizeId : null,   // no longer used, keep old value if it exists for now
            Seq: size.seq
        };
        if (!this.isRugIntlSizeFormValid(RugIntlSize)) return;
        RugIntlSize.Cost = Number(size.cost); 
        RugIntlSize.Price = Number(size.price); 
        RugIntlSize.Seq = Number(size.seq); 
        api.post("Product/UpdateRugIntlSize", RugIntlSize)
        .then(response => {
            if (response.data.success) {
                this.getRugIntlSizes(this.state.productId)
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }    
        })
        .catch(helpers.catchHandler);
    }

    handleDeleteRugIntlSize(id) {
        api
            .delete(`Product/DeleteRugIntlSize/${id}`)
            .then(response => {
                if (response.data.success) { 
                    this.getRugIntlSizes(this.state.productId)
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }  
            }) 
            .catch(helpers.catchHandler);
    }

    handleReviveRugIntlSize(id) {
        api
            .post(`Product/UndeleteRugIntlSize/${id}`)
            .then(response => {
                if (response.data.success) { 
                    this.getRugIntlSizes(this.state.productId)
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }  
            }) 
            .catch(helpers.catchHandler);
    }

    onCancel() {
        this.setState({ redirect: true });
    }

    onCopyRugPrices(productIdsToCopyRugPricesTo, productIdToCopyFrom) {
        const data = {
            productIdsToCopyRugPricesTo: productIdsToCopyRugPricesTo,
            productIdToCopyFrom: productIdToCopyFrom
        }
        api.post("Product/CopyRugPricesToProducts", data)
        .then(response => {
            if (response.data.success) { 
              this.setState({
                showCopyRugPricesModal: false  
              });
            } else {
                this.setState({ 
                  messageFlavor: "danger",
                  message: response.data.message,
                  isMessageVisible: false
                });
            }   
        })
        .catch(error => error);
    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to="/maintainproduct" />;
        };
        const type = this.state.productType;
        const previewStyle = {
            display: 'inline',
            width: 200,
            height: 200
        };

        return (
            <Container fluid className="p-2">
                {this.state.message !== null && (
                    <Row className="mb-2">
                        <Col>
                            <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                        </Col>
                    </Row>
                )}
                {WIDTH_PRODUCT_TYPES.includes(type) && (
                    <Card className="p-3 m-3">
                        <Row>
                            <Col>
                                <h4>Product Widths</h4>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col xs="4">
                                <Select
                                    options={this.state.widthsList}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    // value={this.getSelectedWidthValues(this.state.selectedProductWidths)}
                                    onChange={(options) => this.addSelectedWidths(options)}
                                />
                            </Col>
                            <Col xs="2">
                                <Button
                                    className="primary"
                                    size="sm"
                                    onClick={() => this.saveNewProductWidths()}
                                >
                                    <FontAwesomeIcon icon="save" /> Save
                                </Button>
                            </Col>
                            <Col xs="6">
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Width</th>
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.productWidths.map(pw => (
                                            <tr key={pw.id}>
                                                <td>{pw.widthName}</td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        className="danger btn-outline-secondary float-right"
                                                        onClick={() => this.deleteProductWidth(pw.id)}
                                                    >
                                                        <FontAwesomeIcon icon="trash" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                )}
                {(type === "Intl Rug") && (
                    <Card className="p-3 m-3">
                        <Row className="row-spacing">
                            <Col>
                                <h4>Intl Rug Sizes</h4>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm="12">
                                <ButtonGroup className="float-right btn-group-sm btn-sm">
                                    <Button
                                        className="btn-group-sm btn-sm info"
                                        onClick={() => this.toggleCopyRugPricesModal()}
                                    >
                                        <FontAwesomeIcon icon="clone" /> Copy Prices
                                    </Button>
                                    <Button
                                        className="btn-group-sm btn-sm success"
                                        onClick={() => this.toggleAddRugIntlSize()}
                                    >
                                        <FontAwesomeIcon icon="plus" /> Add Int Rug Size
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.addingRugIntlSize}>
                            <Card className="m-2">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">Add New Size</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>Size Description {helpers.requiredStar()}
                                            <Input
                                                type="text"
                                                name="dsc"
                                                onChange={(event) => this.updateRugIntlSizeState(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                value={this.state.newRugIntlSize.dsc}
                                                maxLength="25"
                                            />
                                        </Col>
                                        <Col>Net Cost {helpers.requiredStar()}
                                            <Input
                                                type="number"
                                                step=".01"
                                                name="cost"
                                                onChange={(event) => this.updateRugIntlSizeState(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                value={this.state.newRugIntlSize.cost}
                                               
                                            />
                                        </Col>
                                        <Col>Sell Price {helpers.requiredStar()}
                                            <Input
                                                type="number"
                                                step=".01"
                                                name="price"
                                                onChange={(event) => this.updateRugIntlSizeState(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                value={this.state.newRugIntlSize.price}
                                            />
                                        </Col>
                                        {/* <Col>Trade Size
                                            <Select
                                                closeMenuOnSelect
                                                value={this.state.newRugIntlSize.tradeSize}
                                                options={this.state.tradeSizesList}
                                                onChange={this.handleRugIntlSizeSelects.bind(this, "tradeSize")}
                                            />
                                        </Col> */}
                                        <Col>Sequence {helpers.requiredStar()}
                                            <Input
                                                type="number"
                                                step="1"
                                                name="seq"
                                                onChange={(event) => this.updateRugIntlSizeState(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                value={this.state.newRugIntlSize.seq}
                                                min="-32768"
                                                max="32767"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="row-spacing">
                                        <Col>
                                            <ButtonGroup className="btn-group btn-access float-right">
                                                <Button
                                                    className="btn primary"
                                                    size="sm"
                                                    onClick={() => this.saveNewRugIntlSize()}
                                                >
                                                    <FontAwesomeIcon icon ="save" /> Save New Size
                                                </Button>
                                                <Button
                                                    className="btn secondary"
                                                    size="sm"
                                                    onClick={() => this.toggleAddRugIntlSize()}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Row className="row-spacing">
                            <Col className="col-12">
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Size Description</th>
                                            <th>Net Cost</th>
                                            <th>Sell Price</th>
                                            {/* <th>Trade Size</th> */}
                                            <th>Sequence</th>
                                            <th width="15%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.rugIntlSizes.map(sz => (
                                            <tr key={sz.id} className="data-row">
                                                {sz.isEditing  
                                                    ? <React.Fragment>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name="dsc"
                                                                onChange={(event) => this.editRugIntlSizeState(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    sz.id
                                                                )}
                                                                value={sz.dsc}
                                                                maxLength="25"
                                                            /> 
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                step=".01"
                                                                name="cost"
                                                                onChange={(event) => this.editRugIntlSizeState(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    sz.id
                                                                )}
                                                                value={sz.cost}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                step=".01"
                                                                name="price"
                                                                onChange={(event) => this.editRugIntlSizeState(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    sz.id
                                                                )}
                                                                value={sz.price}
                                                            />
                                                        </td>
                                                        {/* <td>
                                                            <Select
                                                                bsSize="sm"
                                                                closeMenuOnSelect
                                                                value={helpers.resolveValue(null, sz.tradeSizeId, sz.tradeSizeName)}
                                                                options={this.state.colorFamilyList}
                                                                onChange={(option) => this.editSelectedColorFamily(option, sz.id)}
                                                            />
                                                        </td> */}
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                step="1"
                                                                name="seq"
                                                                onChange={(event) => this.editRugIntlSizeState(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    sz.id
                                                                )}
                                                                value={sz.seq}
                                                                min="-32768"
                                                                max="32767"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ButtonGroup className="float-right">
                                                                <Button
                                                                    className="primary"
                                                                    onClick={() => this.updateRugIntlSize(sz)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="secondary"
                                                                    onClick={() => this.onCancelEditRugIntlSize(sz.id)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </React.Fragment>
                                                    : <React.Fragment>
                                                        {sz.deactivatedAt === null
                                                            ?   <React.Fragment>
                                                                    <td>{sz.dsc}</td>
                                                                    <td>{sz.cost}</td>
                                                                    <td>{sz.price}</td>
                                                                    {/* <td>{sz.tradeSizeName}</td> */}
                                                                    <td>{sz.seq}</td>
                                                                    <td>
                                                                        {/* {sz.deactivatedAt === null
                                                                            ?    */}
                                                                            <ButtonGroup className="float-right">
                                                                                <Button
                                                                                    className="primary btn-outline-secondary"
                                                                                    onClick={() => this.onEditRugIntlSize(sz.id)}
                                                                                    size="sm"
                                                                                >
                                                                                    <FontAwesomeIcon icon="edit" />
                                                                                </Button>
                                                                                <Button
                                                                                    className="danger btn-outline-secondary"
                                                                                    size="sm"
                                                                                    onClick={this.handleDeleteRugIntlSize.bind(this, sz.id)}
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" />
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                                {/* : null
                                                                        } */}
                                                                        {/*:   <Button
                                                                                    className="info"
                                                                                    size="sm"
                                                                                    onClick={this.handleReviveRugIntlSize.bind(this, sz.id)}
                                                                                >
                                                                                    <FontAwesomeIcon icon="recycle" /> Revive
                                                                            </Button>*/}
                                                                    </td>
                                                                </React.Fragment>
                                                            : null
                                                        }
                                                  </React.Fragment>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                )}
                <Card className="p-3 m-3">
                    <Row className="row-spacing">
                        <Col>
                            <h4>Product Colors</h4>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12">
                            <ButtonGroup className="float-right btn-group-sm btn-sm">
                                <Button
                                    className="btn-group-sm btn-sm success"
                                    onClick={() => this.toggleAddProductColor()}
                                >
                                    <FontAwesomeIcon icon="plus" /> Add Product Color
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Collapse isOpen={this.state.addingProductColor}>
                        <Card className="m-2">
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h5 className="page-title">Add New Product Color</h5>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>Color Name {helpers.requiredStar()}
                                        <Input
                                            type="text"
                                            bsSize="sm"
                                            name="name"
                                            onChange={(event) => this.updateProductColorState(
                                                event.target.name,
                                                event.target.value
                                            )}
                                            value={this.state.newProductColor.name}
                                            maxLength="30"
                                        />
                                    </Col>
                                    <Col>Color #
                                        <Input
                                            type="text"
                                            bsSize="sm"
                                            name="number"
                                            onChange={(event) => this.updateProductColorState(
                                                event.target.name,
                                                event.target.value
                                            )}
                                            value={this.state.newProductColor.number}
                                            maxLength="50"
                                        />
                                    </Col>
                                    <Col> Web Images
                                        <div>
                                            <Button
                                                className={this.state.addingImage ? "danger" : "success"}
                                                size="sm"
                                                id="dropzone-toggler"
                                                onClick={this.toggleImages}
                                            >
                                                {this.state.addingImage
                                                    ? <FontAwesomeIcon icon="minus" />
                                                    : <FontAwesomeIcon icon="plus" /> 
                                                } Images
                                            </Button>
                                        </div>
                                    </Col>
                                    {/*
                                    <Col>Sequence {helpers.requiredStar()}
                                        <Input
                                            type="number"
                                            bsSize="sm"
                                            name="seq"
                                            onChange={(event) => this.updateProductColorState(
                                                event.target.name,
                                                event.target.value
                                            )}
                                            value={this.state.newProductColor.seq}
                                            min="-32768"
                                            max="32767"
                                        />
                                    </Col>
                                    */}
                                    <Col>Color Family
                                        <Select
                                            bsSize="sm"
                                            closeMenuOnSelect
                                            value={this.state.newProductColor.colorFamily}
                                            options={this.state.colorFamilyList}
                                            onChange={this.handleColorSelects.bind(this, "colorFamily")}
                                        />
                                    </Col>
                                </Row>
                                {this.state.addingImage 
                                    ? <Row className="row-spacing">
                                            <Col>
                                              <DropzoneUploader
                                                defaultAttachmentType={constants.ATTACHMENT_TYPE.PRODUCT_COLOR_IMAGE_D}
                                                filesCallback={(files) => this.setState({ files: files })}
                                                productAttachmentTypeList={this.props.productAttachmentTypeList}
                                              />
                                            </Col>
                                        </Row>
                                    : null
                                }
                                <Row className="row-spacing">
                                    <Col>
                                        <ButtonGroup className="btn-group btn-access float-right">
                                            <Button
                                                className="btn primary"
                                                size="sm"
                                                onClick={() => this.saveNewProductColor()}
                                            >
                                                <FontAwesomeIcon icon ="save" /> Save New Color
                                            </Button>
                                            <Button
                                                className="btn secondary"
                                                size="sm"
                                                onClick={() => this.toggleAddProductColor()}
                                            >
                                                <FontAwesomeIcon icon="times-circle" /> Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Collapse>
                    <Row className="row-spacing">
                        <Col className="col-12">
                            <Table striped hover size="sm">
                            <thead>
                                <tr>
                                <th>Color Name</th>
                                <th>Color Number</th>
                                <th>Web Images</th>
                                <th>Color Family</th>
                                <th width="180px">Web Representative</th>
                                <th width="15%" />
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.productColors.map(b => (
                                <tr key={b.id} className="data-row">
                                <td>
                                {b.isEditing ? (
                                    <Input
                                        type="text"
                                        bsSize="sm"
                                        name="name"
                                        onChange={(event) => this.editProductColorState(
                                            event.target.name,
                                            event.target.value,
                                            b.id
                                        )}
                                        value={b.name}
                                        maxLength="30"
                                    />
                                ) : b.name}
                                </td>
                                <td>
                                {b.isEditing ? (
                                    <Input
                                        type="text"
                                        bsSize="sm"
                                        name="number"
                                        onChange={(event) => this.editProductColorState(
                                            event.target.name,
                                            event.target.value,
                                            b.id
                                        )}
                                        value={b.number}
                                    />
                                ) : b.number}
                                </td>
                                <td>
                                {b.isEditing ?
                                    <Fragment>
                                    <div>
                                        <Button
                                        className={this.state.addingImage ? "danger" : "success"}
                                        size="sm"
                                        id="dropzone-toggler"
                                        onClick={this.toggleImages}
                                        >
                                        {this.state.addingImage
                                        ? <FontAwesomeIcon icon="minus" />
                                        : <FontAwesomeIcon icon="plus" /> 
                                        } Images
                                        </Button>
                                    </div>
                                    {this.state.addingImage 
                                        ? <Row className="row-spacing">
                                        <Col>
                                            <DropzoneUploader
                                              defaultAttachmentType={constants.ATTACHMENT_TYPE.PRODUCT_COLOR_IMAGE_D}
                                              filesCallback={(files) => this.setState({files: files})}
                                              productAttachmentTypeList={this.props.productAttachmentTypeList}
                                            />
                                        </Col>
                                        </Row>
                                        : null
                                    }
                                    {b.productColorImages.map(image => (
                                        <Col key={image.id}>
                                        <Row>
                                            <Col>
                                            <Label>{image.productAttachmentTypeName}</Label>{" "}
                                            <Button
                                                className="danger btn-outline-secondary"
                                                size="sm"
                                                onClick={() => this.removeProductColorImage(image, b.id)}
                                            >
                                                <FontAwesomeIcon icon="trash" />
                                            </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <img
                                                alt="Preview"
                                                key={image.id}
                                                src={image.url}
                                                style={previewStyle}
                                                onClick={() => window.open(image.url, "_blank")}
                                            />
                                            </Col>
                                        </Row>
                                        </Col>
                                    ))}
                                    </Fragment>
                                : <Fragment>
                                    {b.productColorImages.map(image => (
                                    <div key={image.id}>{image.productAttachmentTypeName}</div>
                                    ))}
                                </Fragment>
                                }
                                </td>
                            {/*
                                <td>
                                    {b.isEditing ? (
                                        <Input
                                            type="number"
                                            bsSize="sm"
                                            name="seq"
                                            onChange={(event) => this.editProductColorState(
                                                event.target.name,
                                                event.target.value,
                                                b.id
                                            )}
                                            value={b.seq}
                                        />
                                    ) : b.seq}
                                </td>
                            */}
                                <td>
                                    {b.isEditing ? (
                                        <Select
                                            bsSize="sm"
                                            closeMenuOnSelect
                                            value={helpers.resolveValue(null, b.colorFamilyId, b.colorFamilyName)}
                                            options={this.state.colorFamilyList}
                                            onChange={(option) => this.editSelectedColorFamily(option, b.id)}
                                        />
                                    ) : b.colorFamilyName}
                                </td>
                                <td className="text-center">
                                    {b.isEditing ? null : (
                                        <Fragment>
                                            {b.seq === 1
                                                ? <FontAwesomeIcon icon="check" className="text-success"/> 
                                                : <Button className="ml-2" color="primary" size="sm" onClick={() => this.setFirst(b.id)}>Set First</Button>
                                            }
                                        </Fragment>
                                        )}
                                </td>
                                <td>
                                    <ButtonGroup className="float-right">
                                    {b.isEditing ? (
                                    <Fragment>
                                        <Button
                                        className="primary"
                                        onClick={() => this.updateProductColor(b)}
                                        size="sm"
                                        >
                                        <FontAwesomeIcon icon="save" /> Save
                                        </Button>
                                        <Button
                                        className="secondary"
                                        onClick={() => this.onCancelEditProductColor(b.id)}
                                        size="sm"
                                        >
                                        <FontAwesomeIcon icon="times-circle" /> Cancel
                                        </Button>
                                    </Fragment>
                                    ) : (
                                    b.deactivatedAt === null
                                    ? (<Fragment>
                                        <Button
                                            className="primary btn-outline-secondary"
                                            onClick={() => this.onEditProductColor(b.id)}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button
                                            className="danger btn-outline-secondary"
                                            size="sm"
                                            onClick={this.handleDeleteProductColor.bind(this, b.id)}
                                        >
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                        </Fragment>) 
                                    : (<Button
                                        className="info"
                                        size="sm"
                                        onClick={this.handleReviveProductColor.bind(this, b.id)}
                                        >
                                        <FontAwesomeIcon icon="recycle" /> Revive
                                        </Button>)
                                    )}
                                    </ButtonGroup>
                                </td>
                                </tr>
                                ))}
                            </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
                {/* } */}
                <Row className="expand-md row-spacing m-3">  
                    <Col className="text-right">
                            <Button
                                className="secondary"
                                size="sm"
                                onClick={this.onCancel}
                            >
                                <FontAwesomeIcon icon="times-circle" /> Back to Products
                            </Button>
                    </Col>
                </Row>
                <Modal
                    isOpen={this.state.showCopyRugPricesModal}
                    toggle={() => this.setState({showCopyRugPricesModal: false})}
                    size="lg"
                >
                    <ModalBody>
                        <MaintainProduct 
                            usedByCopyRugSizesModal={true}
                            defaultFilter={[{filterName: "ProductTypeList", value: [7], labels: ["Intl Rug"], filteredDisplay: "Product Type: Intl Rug" }]}
                            productIdToCopyFrom={this.state.productId}
                            onCopyRugPrices={this.onCopyRugPrices}
                        />
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}