import React, { Component } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label
} from "reactstrap";
import { helpers, constants } from '../utils';
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DropzoneUploader extends Component {
    constructor(props) {
      super(props);
      this.state = {
        files: [],
        file_to_upload: null,
        typeOptions: props.productAttachmentTypeList || props.customerAttachmentTypeList
      };
      this.onDrop = this.onDrop.bind(this);
      this.removeFile = this.removeFile.bind(this);
      this.onDateChange = this.onDateChange.bind(this);
    }

    removeFile(e, file) {
        e.preventDefault();
        const currentState = this.state.files;
        const files = _.filter(currentState, f => f.name !== file.name);
        this.setState({ files: files }, () => {
            this.props.filesCallback(files);
        });
      }


    onDrop(files, rejectedFiles) {
        if (files && _.some(files, f => f.name && f.name.indexOf('%') !== -1)) {
          alert("File names cannot contain the character %");
          return;
        }
        if (rejectedFiles.length > 0) {
          alert("Images must be in JPEG/PNG/GIF/TIFF/PDF formats");
          return;
        }
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          let imageTypeSelection = {value: null, label: ""};
          if (this.props.defaultAttachmentType && _.some(this.state.typeOptions, x => x.value === this.props.defaultAttachmentType.value)) 
          {
            imageTypeSelection = this.props.defaultAttachmentType;
          }
          file.base64EncodedPhoto = event.target.result;
          file.imageType = imageTypeSelection;
          file.dateReceived = new Date();
          const newFiles = _.concat(this.state.files, file);
          this.setState({ files: newFiles });
          this.props.filesCallback(newFiles);
        };
        reader.readAsDataURL(file);
    }

    handleImageTypeSelect(selection, file) {
        const files = this.state.files.slice();
        const fileToUpdate = _.find(files, x => x.base64EncodedPhoto === file.base64EncodedPhoto);
        fileToUpdate.imageType = selection;
        this.setState({
            files: files
        });
    }

  onDateChange(date, file) {
    const files = this.state.files.slice();
    const fileToUpdate = _.find(files, x => x.base64EncodedPhoto === file.base64EncodedPhoto);
    fileToUpdate.dateReceived = date;
    this.setState({
      files: files
    });
  }

    render() {
        const previewStyle = {
            display: 'inline',
            width: 200,
            height: 200
        };
        return (
            <Container fluid>
                <Row className="rowSpacing">
                    <Col xs="12">
                        <Card className="text-center">
                            <CardBody>
                                <CardTitle>Drop file or click on the icon below</CardTitle>
                                <Dropzone
                                    className="col-xs-4"
                                    onDrop={this.onDrop}
                                    accept={this.props.accept ? this.props.accept : "image/jpeg, image/png, image/gif, image/tiff, application/pdf"}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon="file-upload" size="6x" />
                                    </div>
                                </Dropzone>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowSpacing">
                    <Col xs="12">
                        <Card>
                            <CardTitle style={{ textAlign: "center" }}>Files to be uploaded</CardTitle>
                            <CardBody>
                                <Row className="row-spacing">
                                    {this.state.files.map(f => (
                                        <Col key={f.name}>
                                            <Row className="mb-2">
                                                <Col>
                                                  {f.name.toUpperCase().indexOf('PDF') === -1
                                                    ? (<img alt="Preview" key={f.preview} src={f.preview} style={previewStyle} />)
                                                    : <span>({f.name} <FontAwesomeIcon icon='file-pdf' />)</span>
                                                  }
                                                </Col>
                                                <Col>
                                                    <Button
                                                        className="danger btn-outline-secondary"
                                                        size="sm"
                                                        onClick={e => this.removeFile(e, f)}
                                                    >
                                                        <FontAwesomeIcon icon="trash" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                          {this.props.fileUploadOnly
                                              ? null
                                              : (<Row className="mb-2">
                                                    <Col xs="4">
                                                      <Label>Date Received {helpers.requiredStar()}</Label>
                                                      <DatePicker
                                                        selected={f.dateReceived}
                                                        className="form-control"
                                                        placeholderText="Select date received"
                                                        onChange={(e) => this.onDateChange(e, f)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                        <Label>Select File Type{helpers.requiredStar()}</Label>
                                                        <Select
                                                            bsSize="sm"
                                                            closeMenuOnSelect
                                                            options={this.state.typeOptions}
                                                            value={f.imageType}
                                                            onChange={(option) => this.handleImageTypeSelect(option, f)}
                                                        />
                                                    </Col>
                                                </Row>)
                                          }
                                    </Col>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default DropzoneUploader;