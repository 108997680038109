import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Alert,
  Button,
  Label,
  Input,
} from "reactstrap";
import api from "../utils/api";
import helpers from "../utils/helpers";

export default class SqForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      redirect: false,
      webEmail: null,
      accountNo: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  isFormValid() {
    let warnings = [];
    if (!this.state.webEmail && !this.state.accountNo) {
      warnings.push("An email address or an account number is required");
    }
    if (this.state.webEmail && this.state.webEmail.length > 0 && this.state.accountNo && this.state.accountNo.length > 0 ) {
      warnings.push("Please enter either an email address or an account number");
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(","),
        messageFlavor: "danger",
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  handleSubmit() {
    if (!this.isFormValid()) return;
    api
      .post("web/accountForgotPassword", {
        WebEmail: this.state.webEmail || null,
        AccountNo:
          this.state.accountNo
            && this.state.accountNo.length > 0
              ? parseInt(this.state.accountNo)
              : null
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            messageFlavor: "success",
            message:
              "If we have an account with information, we've just sent a reset link to email on file!",
          });
          setTimeout(() => {
            api.redirectToSquarespacePage("/")
          }, 5000);
        } else {
          this.setState({ message: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    return (
      <Container className="mt-5 squarespaceContainer">
        <Row>
          <Col className="ml-3">
            <Card>
              <CardBody>
                <div>
                  <Link to="/sq/sign_in_or_register">
                    Wait, I already have an account - take me to log in!
                  </Link>
                </div>
                <div>
                  {this.state.message !== null ? (
                    <Alert className={this.state.messageFlavor}>
                      {this.state.message}
                    </Alert>
                  ) : null}
                </div>
                <div>
                  <form>
                    <Row className="mt-2 mb-2">
                      <Col>
                        <Label className="Label">
                          Enter your e-mail address and we will send you a reset link.
                        </Label>
                        <Input
                          type="text"
                          value={this.state.webEmail}
                          id="webEmail"
                          onChange={this.handleChange}
                          placeholder="Email"
                          maxLength="150"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                      <Col>
                        <Label className="Label">
                         OR Enter your account number and we will send you link to the email on file.
                        </Label>
                        <Input
                          type="text"
                          value={this.state.accountNo}
                          id="accountNo"
                          onChange={this.handleChange}
                          placeholder="Account #"
                          maxLength="100"
                        />
                      </Col>
                    </Row>
                    <Button
                      className="sqLoginButton float-right text-white"
                      onClick={this.handleSubmit}
                    >
                      Reset Password
                    </Button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
