import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Table,
    Alert,
    Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import _ from 'underscore';
import {
    api,
    filter_helpers,
    constants
} from '../utils';
import {
    FilterSet,
    FilterBoolean,
    FilterText,
    FilterMultiSelect,
    SortHeader,
    Pager
} from '../components';

const filter_any_item = { value: null, label: '(Any)' };

export default class MaintainProduct extends Component {
    constructor(props) {
        super(props);
        let lastProductFilters = null;
        try {
          lastProductFilters = localStorage.getItem('lastProductFilters');
        } catch {}
        const defaultFilters = lastProductFilters
          ? JSON.parse(lastProductFilters)
          : [{ filterName: "ActiveOnly", value: true, filteredDisplay: "" }];
        this.state = {
            pagedList: {
                list: [],
                //pageSize: 15,
                pageSize: 100, //setting all lists to 100
                pageNumber: 1
            },
            mode: 'view',
            sort_field: 'DCName',
            sort_dir: 'asc',
            added_count: 0,
            filters: props.defaultFilter || defaultFilters,
            product_name_list: [],
            redirectTo: null,
            // rugIntlCollectionList: [],
            DC_product_list: [],
            active_list: [],
            fullList: [],
            messageFlavor: 'danger',
            message: null,
            productIdsToCopyRugPricesTo: []
        };
        this.getProductSelectsData = this.getProductSelectsData.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onClone = this.onClone.bind(this);
        this.handleRevive = this.handleRevive.bind(this);
        this.setPage = this.setPage.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.onCancelDialog = this.onCancelDialog.bind(this);
        this.onSelectProduct = this.onSelectProduct.bind(this);
    }

    componentDidMount() {
        this.getProductSelectsData();
        this.refreshList();
    }

    getProductSelectsData() {
        api
            .fetch("Product/GetProductSelectsData")
            .then(response => {
                this.setState({
                    productTypeList: _.reject(response.data.productTypeList, pt => pt.value == constants.PRODUCT_TYPE.RUG.value),
                    // rugIntlCollectionList: response.data.rugIntlCollectionList,
                    productStatusList: response.data.productStatusList,
                    suppliersList: response.data.suppliersList
                });
            });
    }

    onCancelDialog() {
        this.setState({
            mode: 'view'
        });
    }

    refreshList(sort_field, sort_direction, filters) {
        let filterList = filters || this.state.filters;
        const payload = {
            Page: this.state.pagedList.pageNumber,
            PageSize: this.state.pagedList.pageSize,
            SortField: sort_field || this.state.sort_field,
            SortDir: sort_direction || this.state.sort_dir,
            // filters: filter_helpers.transform_filters(filters || this.state.filters)
        };

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

        api.post('Product/GetLightPaginatedList', payload)
            .then((response) => {
                let paged_list = response.data;
                paged_list.list = _.map(paged_list.list, item => {
                    return {
                        ...item,
                        is_selected: this.state.productIdsToCopyRugPricesTo.includes(item.id) ? true : false
                    }
                  });
                if(this.props.usedByCopyRugSizesModal) {
                    paged_list.list = _.reject(paged_list.list, item => item.id === this.props.productIdToCopyFrom)
                }
                this.setState({
                    pagedList: paged_list
                });
            })
    }

    onClone(id) {
      if (!window.confirm(`Are you sure you'd like to copy this product?`)) return;
      api.post(`Product/Clone/${id}`).then(response => {
        if (response.data.success) {
          this.setState({ 
            redirectTo: {
              pathname: '/productdetailscontainer',
              state: {
                productId: parseInt(response.data.message, 10)
              }
            }});
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      }).catch(error => console.error(error));
    }

    handleDelete(id) {
      if (!window.confirm(`Are you sure you'd like to delete this product?`)) return;
      api.delete(`Product/${id}`).then(response => {
        if (response.data.success) {
            this.refreshList();
        } else {
            this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      }).catch(error => console.error(error));
    }

    handleRevive(id) {
        api.post(`Product/undelete/${id}`)
        .then(response => {
            if (response.data.success) {
                this.refreshList();
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }
        })
        .catch(error => error);
    }

    filterChange(changedFilter) {
      const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
      try {
        localStorage.setItem("lastProductFilters", JSON.stringify(filters));
      } catch {}
      this.setState({ filters: filters, clearValue: false });
      this.refreshList(null, null, filters);
    }

    clearFilters() {
      const filters = [];
      try {
        localStorage.setItem("lastProductFilters", JSON.stringify(filters));
      } catch {}
      this.setState({ filters: filters, clearValue: true }, () => this.refreshList(null, null, filters));
    //   this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    doSort(field, direction) {
        this.setState({ sort_dir: direction, sort_field: field });
        this.refreshList(field, direction);
      }

    setPage(page) {
        const newPagedList = Object.assign({}, this.state.pagedList);
        newPagedList.pageNumber = page;
        this.setState({ pagedList: newPagedList }, () => this.refreshList());
    }

    onSelectProduct(e, product) {
        if (_.contains(this.state.productIdsToCopyRugPricesTo, product.id)) {
          let tempList = this.state.productIdsToCopyRugPricesTo.slice();
          tempList = _.reject(tempList, function(id) { return id == product.id})
          this.setState({
            productIdsToCopyRugPricesTo: tempList
          }); 
        }
        else {
          let tempList = this.state.productIdsToCopyRugPricesTo.slice();
          tempList.push(product.id);
          this.setState({
            productIdsToCopyRugPricesTo: tempList
          });
        }
        const indexToEdit = _.findIndex(this.state.pagedList.list, {id: product.id});
        let tempList2 = this.state.pagedList.list.slice(); 
        tempList2[indexToEdit].is_selected = !tempList2[indexToEdit].is_selected;
        this.setState({
          pagedList: {
            ...this.state.pagedList,
            list: tempList2
          } 
        })
    }

    render() {
      if (this.state.redirectTo) {
        return (<Redirect to={this.state.redirectTo} />);        
      }
      return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message !== null && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col>
                                {this.props.usedByCopyRugSizesModal
                                    ? null
                                    :   <Link
                                            to={{ pathname: '/productdetailscontainer' }}
                                            style={{ color: "white", textDecoration: 'none' }}
                                            className="float-right"
                                        >
                                            <Button
                                                className="btn-group-sm btn-sm success"
                                            >
                                                <FontAwesomeIcon icon="plus" /> Add Product
                                            </Button>
                                        </Link>
                                }
                                <h3 className="pull-left page-title">{this.props.usedByCopyRugSizesModal ? `Copy Rug Prices To` : `Maintain Products`}</h3>
                            </Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col sm="12">
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters} open={true}>
                                    <Row>
                                        <Col xs="3" xl="3">
                                            <FilterText
                                                filterName="DCName"
                                                displayName="DC Name"
                                                value={this.currentFilterValue('DCName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3" xl={this.props.usedByCopyRugSizesModal ? "3" : "1"}>
                                            <FilterText
                                                filterName="DCNumber"
                                                displayName="DC #"
                                                value={this.currentFilterValue('DCNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3" xl={this.props.usedByCopyRugSizesModal ? "3" : "2"}>
                                            <FilterText
                                                filterName="SupplierName"
                                                displayName="Supplier Name"
                                                value={this.currentFilterValue('SupplierName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3" xl={this.props.usedByCopyRugSizesModal ? "3" : "1"}>
                                            <FilterText
                                                filterName="SupplierNumber"
                                                displayName="Supplier #"
                                                value={this.currentFilterValue('SupplierNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3" xl="3">
                                            <FilterMultiSelect
                                                filterName="ProductTypeList"
                                                displayName="Product Type"
                                                options={this.state.productTypeList}
                                                values={this.currentFilterValue('ProductTypeList')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                        {/* <Col >
                                            <FilterMultiSelect
                                                filterName="RugIntlCollectionList"
                                                displayName="Collection"
                                                options={this.state.rugIntlCollectionList}
                                                values={this.currentFilterValue('RugIntlCollectionList')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col> */}
                                        <Col xs="3" xl={this.props.usedByCopyRugSizesModal ? "3" : "2"}>
                                            <FilterMultiSelect
                                                filterName="ProductStatusList"
                                                displayName="Product Status"
                                                options={this.state.productStatusList}
                                                values={this.currentFilterValue('ProductStatusList')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                        {/* <Col >
                                            <FilterBoolean
                                                filterName="ActiveOnly"
                                                displayName="Active?"
                                                value={this.currentFilterValue('ActiveOnly')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col> */}
                                    {/* </Row>
                                    <Row> */}
                                        <Col xs="3" xl="3">
                                            <FilterText
                                                filterName="ColorName"
                                                displayName="Color Name"
                                                value={this.currentFilterValue('ColorName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3" xl={this.props.usedByCopyRugSizesModal ? "3" : "1"}>
                                            <FilterText
                                                filterName="ColorNumber"
                                                displayName="Color #"
                                                value={this.currentFilterValue('ColorNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3" xl="3">
                                            <FilterMultiSelect
                                                filterName="SupplierList"
                                                displayName="Supplier"
                                                options={this.state.suppliersList}
                                                values={this.currentFilterValue('SupplierList')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                        <Col xs="3" xl={this.props.usedByCopyRugSizesModal ? "3" : "1"}>
                                            <FilterBoolean
                                                filterName="activeOnly"
                                                displayName="Active Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('activeOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} 
                                            />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SortHeader
                                                    displayName="DC Name"
                                                    field="dc_name"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'dc_name'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="DC Style #"
                                                    field="dcNumber"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'dcNumber'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Supplier Name"
                                                    field="supplierName"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'supplierName'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Supplier Style #"
                                                    field="SupplierNumber"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'SupplierNumber'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Supplier"
                                                    field="Supplier"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'Supplier'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                Supplier Phone #
                                            </th>
                                            <th width="15%">{this.props.usedByCopyRugSizesModal ? `Select` : null}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.pagedList.list.map(p => (
                                            <tr key={p.id} className="data-row">
                                                <td>{p.dcName}<br /> </td>
                                                <td>{p.dcNumber}</td>
                                                <td>{p.supplierName}</td>
                                                <td>{p.supplierNumber}<br /> </td>
                                                <td>{p.supplierLabel}</td>
                                                <td>{p.supplierPhoneNo}</td>
                                                <td className="text-right">
                                                    {this.props.usedByCopyRugSizesModal
                                                        ?   <div style={{textAlign: "center", verticalAlign: "middle"}}>
                                                                <Input 
                                                                    type="checkbox"
                                                                    checked={p.is_selected} 
                                                                    onChange={(e) => this.onSelectProduct(e, p)}
                                                                />
                                                            </div>
                                                        :  <ButtonGroup>
                                                            {p.deactivatedAt === null ? (
                                                                <React.Fragment>
                                                                <Button
                                                                    className="btn primary btn-outline-secondary"
                                                                    size="sm"
                                                                    tag={Link}
                                                                    to={{
                                                                        pathname: '/productdetailscontainer',
                                                                        state: {
                                                                            productId: p.id
                                                                        }
                                                                    }}
                                                                    style={{ color: "white", textDecoration: 'none' }}
                                                                >
                                                                    <FontAwesomeIcon icon="edit" />
                                                                </Button>
                                                                <Button
                                                                    className="btn info btn-outline-secondary"
                                                                    size="sm"
                                                                    onClick={() => this.onClone(p.id)}
                                                                    title="Copy to new"
                                                                    style={{ color: "white", textDecoration: 'none' }}
                                                                >
                                                                    <FontAwesomeIcon icon="clone" />
                                                                </Button>
                                                                <Button
                                                                    className="btn danger btn-outline-secondary"
                                                                    size="sm"
                                                                    onClick={this.handleDelete.bind(this, p.id)}
                                                                >
                                                                    <FontAwesomeIcon icon="trash" />
                                                                </Button>
                                                                </React.Fragment>
                                                            ) : (
                                                                <Button
                                                                    className="info"
                                                                    size="sm"
                                                                    onClick={this.handleRevive.bind(this, p.id)}
                                                                >
                                                                    <FontAwesomeIcon icon="recycle" /> Revive
                                                                </Button>
                                                            )}
                                                        </ButtonGroup>
                                                    } 
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {this.props.usedByCopyRugSizesModal
                                    ?   <Button
                                            className="primary float-right"
                                            size="sm"
                                            onClick={() => this.props.onCopyRugPrices(this.state.productIdsToCopyRugPricesTo, this.props.productIdToCopyFrom)}
                                            disabled={!this.state.productIdsToCopyRugPricesTo}
                                        >
                                            {/* <FontAwesomeIcon icon="recycle" />  */}
                                            Copy To Selected
                                            <div style={{fontStyle: "italic"}}>{`${this.state.productIdsToCopyRugPricesTo.length} selected`}</div>
                                        </Button>
                                    : null
                                }
                                <Pager {...this.state.pagedList} callBack={this.setPage} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

