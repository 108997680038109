import '@babel/polyfill';
import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import 'react-s-alert/dist/s-alert-default.css';
import { Alert as AlertStrap} from 'reactstrap';
import _ from 'lodash';
import {
    AddCustomersAndContacts,
    AgentAdmin,
    AgentAdminContainer,
    AreaAdmin,
    Claims,
    // Collections,
    ConfigurationSettingsAdmin,
    ContactList,
    ConstructionTypeAdmin,
    CustomerAdmin,
    CustomerAdminContainer,
    CustomerDetailsView,
    SqForgotPassword,
    SqResetPassword,
    ErrorMessage,
    Expense,
    EditClaim,
    EditExpense,
    EditSupplier,
    ExpenseCategory,
    ExpensePayToAdmin,
    ForgotPassword,
    FreightCarrierAdmin,
    Header,
    // Home,
    MaintainBooks,
    MaintainProduct,
    MaintainSamples,
    MemoOrders,
    MemoOrderLines,
    MemoSizes,
    MemoStockOrders,
    MillReserves,
    MultiOrderPayable,
    NotFound,
    Orders,
    OrderEntry,
    Pile,
    ProductDetailsContainer,
    Profile,
    Reports,
    ResetPassword,
    // RugComplexities,
    SalesTransfer,
    ShippingConsole,
    SignIn,
    SqSalesRepScreen,
    Squarespace,
    Suppliers,
    SqOrders,
    // TradeSize,
    UpdatePassword,
    UserAdmin,
    ViewSupplier,
    Width,
    YarnSupplierBrand,
    SqSignIn,
    SqLogout,
    FiberGroupAdmin,
    SqPriceList,
    Impersonate
} from './pages';
import { api, helpers } from "./utils";

const CHECK_LAST_ACTIVITY = 300000; // five minutes
const SQUARESPACE_CHECK_LAST_ACTIVITY = 900000; // fifteen minutes
const INACTIVITY_TIMEOUT = 43200000; // 12 hours
const LOGIN_TIMEOUT = 43200000; // 12 hours

const LoginRoute = ({ ...extraProps }) => (
  <Route {...extraProps} render={(props) => {
    return (
      extraProps.authenticated()
          ? <Redirect to='/home' />
          : <SignIn {...extraProps} />
      //<Redirect to={{ pathname: '/', state: { logout: extraProps.logout }}}/>
    );
  }} />
);

const AuthRoute = ({ component: Component, currentUser, app: rootComponent, ...extraProps }) => (
  <Route {...extraProps} render={(props) => {
      if (helpers.mustChangePassword(currentUser))
      {
        return (<UpdatePassword {...extraProps} currentUser={currentUser} />)
      }
      if (!api.userToken()) 
      {
        rootComponent.Logout('You are now logged out. Please sign in to continue.');
        return (<Redirect to="/" />);
      }
      return (
        extraProps.authenticated()
            ? <Component {...extraProps} 
            currentUser={currentUser} 
            // authenticated={rootComponent.Authenticated}
            setImpersonation={rootComponent.SetImpersonation}
            setCurrentUser={rootComponent.SetCurrentUser}
            />
            : <Redirect to='/' />
      );
  }} />
);

const SQRoute = ({ component: Component, currentCustomer, app: rootComponent, ...extraProps }) => {
  if (extraProps && extraProps.location && extraProps.location.pathname && _.includes(extraProps.location.pathname, "logout")) {
    if(currentCustomer){
      rootComponent.SquarespaceLogout();
      return null;
    }
  }
  return (
  <Route
    {...extraProps}
    render={(_props) => {
      return <Component {...extraProps} currentCustomer={currentCustomer} squarespace={true}/>;
    }}
  />
)};

const nowTime = () => {
  return new Date().getTime();
}

export default class App extends Component {
  constructor(props) {
    super(props);
    let token = null;
    try {
      token = localStorage.getItem('token');
    } catch (e) {
      console.log(e)
    }
    const squarespace =
      _.includes(window.location.href, '/sq/')
        || _.includes(window.location.href, '/all-carpet')
        || _.includes(window.location.href, '/log-in-sign-up')
        || _.includes(window.location.href, '/shopping-cart');
    ;
    let currentUser = token
      ? JSON.parse(localStorage.getItem('currentUser'))
      : null;
    let parsedCustomerCookie = api.getCookie('SQ_CUSTOMER', true);
    let customerToken = api.getCookie('SQ_TOKEN', false);
    let currentCustomer =
      parsedCustomerCookie
        && parsedCustomerCookie !== ""
        ? parsedCustomerCookie : null;
    // this is for local development
    if (!currentCustomer) {
      try {
        currentCustomer = localStorage.getItem('currentCustomer')
          && JSON.parse(localStorage.getItem('currentCustomer')) || null;
      } catch (e) {
        console.log(e)
      }
    }
    let loa = null;
    try {

      loa = localStorage.getItem('loggedOnAt"');
    } catch (e) {
      console.log(e)
    }
    const loggedOnAt = parseInt(loa) ? parseInt(loa) : 0;
    const adminToken = localStorage.getItem('adminToken');
    this.state = {
      authenticationToken: token,
      currentUser: currentUser,
      loggedOnAt: loggedOnAt,
      logoutMessage: null,
      squarespace: squarespace,
      currentCustomer: currentCustomer,
      customerToken: customerToken,
      loggingOut: false,
      impersonating: adminToken === null ? false : true,
      ClearImpersonationMessage: null,
      ClearImpersonationMessageFlavor: null
    };
    this.Logout = this.Logout.bind(this);
    this.StartTimer = this.StartTimer.bind(this);
    this.SetLastActivity = this.SetLastActivity.bind(this);
    this.SetCurrentUser = this.SetCurrentUser.bind(this);
    this.Authenticated = this.Authenticated.bind(this);
    this.onDismissSignInMessage = this.onDismissSignInMessage.bind(this);
    this.SetImpersonation = this.SetImpersonation.bind(this);
    this.ClearImpersonation = this.ClearImpersonation.bind(this);
    this.ClearImpersonationPersistence = this.ClearImpersonationPersistence.bind(this);

    // squarespace specific to utilize cookies instead of token / local storage authentication
    this.SquarespaceLogout = this.SquarespaceLogout.bind(this);
    this.SetCurrentCustomer = this.SetCurrentCustomer.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.SetLastActivity);
    this.StartTimer();
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.SetLastActivity);
    clearInterval(this.intervalId);
  }

  StartTimer() {
    if (this.state.squarespace) {
      this.intervalId = setInterval(this.CompareSquarespaceLastActivity, SQUARESPACE_CHECK_LAST_ACTIVITY, this);
      this.VerifySquarespaceAuthentication(this);
      return;
    }
    this.intervalId = setInterval(this.CompareTimerToLastActivity, CHECK_LAST_ACTIVITY, this);
    this.VerifyAuthentication();
  }

  SetImpersonation(user, token) {
    if (this.state.impersonating) {
      return; // do not allow impersonation a 2nd time if we're already in that mode
    }
    // remember the admin user's details
    const adminUser = JSON.parse(localStorage.getItem('currentUser'));
    const adminUserToken = localStorage.getItem('token');
    // clear storage
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    // set the impersonation details
    // user = enhanceUser(user);
    localStorage.setItem('token', token);
    localStorage.setItem('currentUser', JSON.stringify(user));
    
    // remember the admin details for later
    localStorage.setItem('adminToken', adminUserToken);
    localStorage.setItem('adminUser', JSON.stringify(adminUser));
    this.setState({
      impersonating: true,
      currentUser: user,
      authenticationToken: token
    });
  }

  ClearImpersonationPersistence() {
    if (localStorage.getItem('adminUser')) {
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminUser');
    }
  }

  ClearImpersonation() {
    if (!this.state.impersonating) {
      return; // do not allow clear of the primary user if we're not impersonating
    }
    // get the original admin user
    const adminUser = JSON.parse(localStorage.getItem('adminUser'));
    const adminUserToken = localStorage.getItem('adminToken');
    // clear storage
    this.ClearImpersonationPersistence();
    // set the admin user back as primary
    localStorage.setItem('token', adminUserToken);
    localStorage.setItem('currentUser', JSON.stringify(adminUser));
    this.setState({
      impersonating: false,
      currentUser: adminUser,
      authenticationToken: adminUserToken
    });
    this.setState({ ClearImpersonationMessageFlavor: "success", ClearImpersonationMessage: "You are now leaving Impersonation" });
        setTimeout(() => window.location.reload(), 3000, this);
  }

 

  CompareTimerToLastActivity(rootComponent) {
      if (!localStorage.getItem('lastActivity')) rootComponent.SetLastActivity();
      if (nowTime() - parseInt(localStorage.getItem('lastActivity')) > INACTIVITY_TIMEOUT) {
        rootComponent.Logout('You have been logged out due to inactivity. Please log back in to continue.');
      }
      rootComponent.VerifyAuthentication();
  }

  CompareSquarespaceLastActivity(rootComponent) {
    if (!localStorage.getItem('lastActivity')) rootComponent.SetLastActivity();
    if (nowTime() - parseInt(localStorage.getItem('lastActivity')) > INACTIVITY_TIMEOUT) {
      rootComponent.SquarespaceLogout('You have been logged out due to inactivity. Please log back in to continue.');
      if (rootComponent.state.squarespace) {
        // window.location.pathname = "/all-carpet";
      }
    }
    rootComponent.VerifySquarespaceAuthentication(rootComponent);
  }

  VerifyAuthentication() {
      if (!this.state.authenticationToken) return; // not logged in, no need to verify anything
      if ((nowTime() - this.state.loggedOnAt) < LOGIN_TIMEOUT) return;
      api.fetch("Public/AuthCheck?nocache=" + nowTime()).then(r => {
          if (r.data.success === false) {
              this.Logout('Your session has timed out. Please log in.');
          }
      }).catch(e => {
          console.error("Failed auth check", e);
          this.Logout('Your session has timed out. Please log in.');
      });
  }

  VerifySquarespaceAuthentication(rootComponent) {
    if (!rootComponent.state.customerToken) return; // not logged in, no need to verify anything
    if ((nowTime() - rootComponent.state.loggedOnAt) < LOGIN_TIMEOUT) return;
    const tokenCookie = api.getCookieTimeout('SQ_TOKEN', false);
    if ((nowTime() - rootComponent.state.loggedOnAt) < LOGIN_TIMEOUT) return;

    // NEED TO FIX
    // api.fetch("Web/AuthCheck?nocache=" + nowTime()).then(r => {
    //   if (r.data.success === false) {
    //     this.SquarespaceLogout('Your session has timed out. Please log in.');
    //   }
    // }).catch(e => {
    //   console.error("Failed auth check", e);
    //   this.SquarespaceLogout('Your session has timed out. Please log in.');
    // });
  }

  SetLastActivity() {
    if (!this.state.authenticationToken || (this.state.squarespace && !this.state.customerToken)) return; // not logged in
    localStorage.setItem('lastActivity', nowTime());
    if (this.state.logoutMessage) 
    {
        this.setState({ logoutMessage: null });
    }
  }

  Logout(logoutMessage = null) {
    if(this.state.impersonating) 
    {
      this.ClearImpersonationPersistence();
    }
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('loggedOnAt');
      localStorage.removeItem('lastActivity');
      this.setState({ impersonating: false, currentUser: null, authenticationToken: null, loggedOnAt: null, logoutMessage: logoutMessage });
  }

  SquarespaceLogout(logoutMessage = null) {
    if(this.state.loggingOut) return;
    this.setState({loggingOut:true }, () => {
      api.fetch("web/CustomerLogout").then((response) => {
        api.clearCookies();
        localStorage.removeItem('customerToken');
        localStorage.removeItem('currentCustomer');
        localStorage.removeItem('customRug');
        localStorage.removeItem('orderCartItems');
        localStorage.removeItem('sampleCartItems');
        return response;
      }).then((response) => {
        if (response) { 
          this.setState({ currentCustomer: null, customerToken: null, loggedOnAt: null, logoutMessage: logoutMessage }, () => {
            api.redirectToSquarespacePage('/log-in-sign-up')
          });
        }
      }).catch(helpers.catchHandler)
      .finally(() => this.setState({loggingOut: false}));
    });
  }

  SetCurrentUser(user, token) {
    if (token) {
      localStorage.setItem('token', token);
    }
    localStorage.setItem('currentUser', JSON.stringify(user));
    let newState = {currentUser: user, authenticationToken: token};
    if (this.state.logoutMessage) { // if you just logged in and there's still a message set, clear it
        newState = {...newState, logoutMessage: null}
        this.setState(newState);
    }
    this.setState({
        currentUser: user,
        authenticationToken: token
    });
  }

  SetCurrentCustomer(customer, token, ) {
    try {
      if (token) {
        localStorage.setItem('customerToken', token);
      }
      localStorage.setItem('currentCustomer', JSON.stringify(customer));
    } catch (e) {
      console.log('setting current user')
    }
    let newState = {currentCustomer: customer, customerToken: token};
    if (this.state.logoutMessage) { // if you just logged in and there's still a message set, clear it
      newState = {...newState, logoutMessage: null}
      this.setState(newState);
    } else {
      try {
        localStorage.setItem('loggedOnAt', nowTime());
      } catch (e) {
        console.log('setting logged in at')
      }
    }
    this.setState({
      currentCustomer: customer,
      customerToken: token
    });
  }

  onDismissSignInMessage() {
      this.setState({
          logoutMessage: null
      });
  }

  Authenticated() {
    if (this.state.squarespace) {
      return this.state.customerToken !== null;
    }
    return this.state.authenticationToken !== null;
  }

  render() {
    return (
      <Router>
        <div>
          <Header logout={this.Logout} setImpersonation={this.SetImpersonation} clearImpersonation={this.ClearImpersonation} currentUser={this.state.currentUser} squarespace={this.state.squarespace} impersonating={this.state.impersonating}/>
          {this.state.ClearImpersonationMessage === null
          ? null 
          : (<AlertStrap color={this.state.ClearImpersonationMessageFlavor}>{this.state.ClearImpersonationMessage}</AlertStrap>)}
          <AlertStrap
              color="success"
              isOpen={this.state.logoutMessage ? true : false}
              toggle={this.onDismissSignInMessage}
          >
              {this.state.logoutMessage}
          </AlertStrap>
          <Alert
            effect="stackslide"
            position="top"
            stack={{ limit: 1 }}
            timeout={2500}
            html={true}
            offset={1}
            z-index={4000}
            preserveContext
          />
          <Switch>
            <Route path="/forgot_password" component={ForgotPassword} currentUser={this.state.currentUser} email={this.state.email} />
            <Route path="/reset_password/token/:resetToken" component={ResetPassword} currentUser={this.state.currentUser} email={this.state.email} />
            <Route
              path="/forgot_account_password"
              component={SqForgotPassword}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
            />
            <Route
              path="/reset_account_password/token/:resetToken"
              component={SqResetPassword}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
            />            
            <SQRoute
            path="/sq/priceList/:priceListType"
            component={SqPriceList}
            currentCustomer={this.state.currentCustomer}
            email={this.state.email}
            squarespace={true}
            setCurrentCustomer={this.SetCurrentCustomer}
            app={this}
            />
            <SQRoute
              path="/sq/orders"
              component={SqOrders}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
            />
            <SQRoute
              path="/log-in-sign-up"
              component={SqSignIn}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
              SquarespaceLogout={this.SquarespaceLogout}
            />
            <SQRoute
              path="/sq/logout"
              component={SqLogout}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
              SquarespaceLogout={this.SquarespaceLogout}
            />
            <SQRoute
              path="/sq/sign_in_or_register"
              component={SqSignIn}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
              SquarespaceLogout={this.SquarespaceLogout}
            />
            <SQRoute
              path="/sq/find-a-sales-rep"
              component={SqSalesRepScreen}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              SetCurrentCustomer={this.SetCurrentCustomer}
              app={this}
            />
            <SQRoute
              path="/sq"
              component={Squarespace}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
            />
            <SQRoute
              path="/all-carpet"
              component={Squarespace}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
            />
            <SQRoute
              path="/all-rugs"
              component={Squarespace}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
            />
            <SQRoute
              path="/shopping-cart"
              component={Squarespace}
              currentCustomer={this.state.currentCustomer}
              email={this.state.email}
              squarespace={true}
              setCurrentCustomer={this.SetCurrentCustomer}
              app={this}
            />
            <LoginRoute exact path="/" authenticated={this.Authenticated} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/impersonate" app={this} component={Impersonate} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/home" app={this} component={Orders} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/useradmin" app={this} component={UserAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/areaadmin" app={this} component={AreaAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/agentadmin" app={this} component={AgentAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/claims" app={this} component={Claims} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/customeradmin" app={this} component={CustomerAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/editclaim/:id" app={this} component={EditClaim} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/orders" app={this} component={Orders} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/orderentry" app={this} component={OrderEntry} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/profile" app={this} component={Profile} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/expensecategory" app={this} component={ExpenseCategory} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/expensepaytoadmin" app={this} component={ExpensePayToAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/freightcarriers" app={this} component={FreightCarrierAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/salestransfer" app={this} component={SalesTransfer} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />          
            {/* <AuthRoute exact path="/collections" app={this} component={Collections} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} /> */}
            <AuthRoute exact path="/configurationsettingsadmin" app={this} component={ConfigurationSettingsAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/constructiontypeadmin" app={this} component={ConstructionTypeAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/width" app={this} component={Width} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/pile" app={this} component={Pile} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/maintainbooks" app={this} component={MaintainBooks} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/maintainsamples" app={this} component={MaintainSamples} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/memoorders" app={this} component={MemoOrders} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/memoorderLines" app={this} component={MemoOrderLines} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/memosizes" app={this} component={MemoSizes} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/memostockorders" app={this} component={MemoStockOrders} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/millreserves" app={this} component={MillReserves} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/shippingconsole" app={this} component={ShippingConsole} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/suppliers" app={this} component={Suppliers} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/expense" app={this} component={Expense} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/editExpense/:id" app={this} component={EditExpense} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            {/* <AuthRoute exact path="/tradesize" app={this} component={TradeSize} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} /> */}
            <AuthRoute exact path="/maintainproduct" app={this} component={MaintainProduct} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/productdetailscontainer" app={this} component={ProductDetailsContainer} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/yarnsupplierbrand" app={this} component={YarnSupplierBrand} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/fibergroup" app={this} component={FiberGroupAdmin} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/updatepassword" app={this} component={UpdatePassword} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/addcustomersandcontacts" app={this} component={AddCustomersAndContacts} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/customeradmincontainer/:customerId?/:activeTab?" app={this} component={CustomerAdminContainer} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/contactlist" app={this} component={ContactList} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/viewSupplier" app={this} component={ViewSupplier} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/editSupplier/:id" app={this} component={EditSupplier} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/customerdetailsview" app={this} component={CustomerDetailsView} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            {/* <AuthRoute exact path="/rugcomplexities" app={this} component={RugComplexities} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} /> */}
            <AuthRoute exact path="/reports" app={this} component={Reports} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/multiorderpayables" app={this} component={MultiOrderPayable} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <AuthRoute exact path="/editagent" app={this} component={AgentAdminContainer} authenticated={this.Authenticated} logout={this.Logout} currentUser={this.state.currentUser} setCurrentUser={this.SetCurrentUser} />
            <Route component={NotFound} />
            <ErrorMessage />
          </Switch>
        </div>
      </Router>
    );
  }
}
